<h3>{{fileName}}&nbsp;<button  mat-mini-fab   color="secondary" type="button" (click)="downloadFile();"><mat-icon >save</mat-icon></button></h3>
<table mat-table [dataSource]="dataSource" >

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef > Time </th>
    <td mat-cell *matCellDef="let measure"> {{measure.Time}} </td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef > Value </th>
    <td mat-cell *matCellDef="let measure"> {{measure.Value}} </td>
  </ng-container>

  <ng-container matColumnDef="temperature">
    <th mat-header-cell *matHeaderCellDef > Temppd </th>
    <td mat-cell *matCellDef="let measure"> {{measure.Temppd}} </td>
  </ng-container>

  <ng-container matColumnDef="tempc">
    <th mat-header-cell *matHeaderCellDef > Tempc </th>
    <td mat-cell *matCellDef="let measure"> {{measure.Tempc}} </td>
  </ng-container>

  <ng-container matColumnDef="tempp">
    <th mat-header-cell *matHeaderCellDef > Tempp </th>
    <td mat-cell *matCellDef="let measure"> {{measure.Tempp}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 100]" showFirstLastButtons></mat-paginator>


