import { Component, OnInit } from '@angular/core';
import { SequencerService, SequencerValues } from '../../../services/sequencer.service';
import { MethodService, MethodValues } from '../../../services/method.service';
import { PlannerService, PlannerValues } from '../../../services/planner.service';
import { StatusService, Status, currentStatus } from '../../../services/status.service';

import { FormBuilder, FormControl, Validators, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSelect, MatSelectChange } from '@angular/material/select';

import { interval, Subscription } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-start-analysis',
  templateUrl: './start-analysis.component.html',
  styleUrls: ['./start-analysis.component.scss'],
  providers: [DatePipe]
})
export class StartAnalysisComponent implements OnInit {
  //il form
  private subscriptions: Subscription[]=[];
  form: FormGroup;
  //lo status della macchina
  status: currentStatus={method:'Waiting...', status:'idle'};
  //lista metodi, sequencer e planner
  methodList: Array<MethodValues>;
  sequencerList: Array<SequencerValues>;
  plannerList: Array<PlannerValues>;
  lastMeasure: any;
  lastCalibration: any;
  gasses: object={"71-43-2": "Benzene",	
  "108-88-3":"Toluene",	
  "100-41-4": "Ethylbenzene",	
  "1330-20-7": "Xylenes",
  };
  // Operazione attuale: idle, selected, loading, measuring, stopping
  /* 
    idle, loading, measuring, stopping => sono stati effettivi della macchina, per cui tornati dal servizio
    selected => è uno stato di startAnalysis, e indica semplicemente che da interfaccia è stato selezionato un metodo, una sequenza, un planner
    la sequenza è
    idle => selected => (loading => measuring => stopping => idle) => idle   
  */
  actualOperation: string;

  // queste proprietà delineano lo stato della macchina, 
  /*
    ATTENZIONE: non so se sia la cosa migliore, dipende anche dall'implementazione della macchina
    Se actualMethod è popolato e actualSequencer e actualPlanner sono null  => la macchina eseguirà/sta eseguendo un metodo unico
    Se actualSequencer è popolato, ma actualPlanner è null => la macchina sta eseguendo un Sequencer (per cui actualMethod risulterà o popolato o null, a seconda dello stato)
    Se actualPlanner è popolato => la macchina sta eseguendo un Planner, per cui actualSequencer e actualMethod saranno popolati a seconda sello stato
  */
  actualMethod: string;
  actualSequencer: string;
  actualPlanner: string;
  
  // mostrare o nascondere elementi di interfaccia
  showPoint: boolean;
  showRepeat: boolean;
  showLoadForm: boolean;
  showStartStop: boolean;
  startDisabled: boolean=false;
  stopDisabled: boolean=false;
  
  // abilitazione/disabilitazione pulsanti load e start/stop
  loadDisabled: boolean;
  startStopAnalysisDisabled: boolean;

  // testi dei pulsanti load e start/stop
  loadAnalysisText: string;
  startStopAnalysisText: string;

  // valore della runName
  runName: string;
  rtStatus:object={ColumnTemp:0,PreconcTemp:0};
  startPanelClass: string="";
  selectedTitleClass: string="";
  selectedMethodClass: string="";
  selectedSequencerClass: string="";
  selectedPlannerClass: string="";
  
  constructor(private datePipe: DatePipe,private statusService:StatusService, private planner:PlannerService, private method:MethodService, private sequencerService: SequencerService ,private fb: FormBuilder,private route: ActivatedRoute, private location: Location,private router:Router) { }


  ngOnInit() {
    this.method.getList().subscribe(res => {
      this.methodList = res;
    });
  
    this.initializeStatus();
    
    this.subscriptions.push(interval(5000).subscribe((value: number) => {
      this.initializeStatus();
    }));

    
  }
  

  initializeStatus() {
    this.statusService.getLastMeasure().subscribe(res => {
      this.lastMeasure=res;
      console.log("last measure",this.lastMeasure);
    });

    this.statusService.getLastCalibration().subscribe(res => {
      this.lastCalibration=res;
      console.log("last calibration",this.lastCalibration);
    });
    this.statusService.getStatus().subscribe(res => {
      console.log("Ecco il res",res);
      this.status = res;
      //inizializzo l'interfaccia a seconda dello stato
      
      
    });
    this.statusService.getRtStatus().subscribe(res => {
      console.log("Ecco il res",res);
      this.rtStatus = res;
      //inizializzo l'interfaccia a seconda dello stato
      console.log(this.rtStatus);
      console.log("STATUS",this.status);
      this.rtStatus['statusOK']=this.rtStatus['status'].substr(1,this.rtStatus['status'].length-1);
      if ((this.rtStatus['status']=='eReady'))
        this.startDisabled= false;
      else
        this.startDisabled= true;

      if (this.rtStatus['status']=='eRunning')
        this.stopDisabled=false;
      else
        this.stopDisabled=true;
      
    });
  }
  // creazione del form
 
 
  startAnalysis(){
    this.statusService.start().subscribe(res => {
      console.log("start",res);
      
    });
    
  }
  startAutoCalib(){
    this.statusService.startAutoCalib().subscribe(res => {
      console.log("start Calibration",res);
      
    });
    
  }
  stopAnalysis(){
    this.statusService.stop().subscribe(res => {
      console.log("start",res);
     
    });
    
  }
  generateArray(obj){
    return Object.keys(obj).map((key)=>{ return {key:key, value:obj[key]}});
  }
  gotoMeasure(){

  }
  ngOnDestroy(){
    this.subscriptions.forEach(sub=>{
      sub.unsubscribe();
    })
  }
}
