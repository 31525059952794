import { Component, OnInit, Input, DoCheck, KeyValueDiffers, OnChanges } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';

import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/grid-light';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
HC_exporting(Highcharts);
HC_export_data(Highcharts);
theme(Highcharts);

@Component({
  selector: 'app-chromatogram',
  templateUrl: './chromatogram.component.html',
  styleUrls: ['./chromatogram.component.scss']
})
export class ChromatogramComponent implements OnInit,OnChanges,DoCheck {
  @Input() fileName: string;
  @Input() extraOption: Array<object>;
  chromatogramValues: Array<object>;
  differ: any;
  // highcharts
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  
  chartOptions: Highcharts.Options = { 
    chart: {
      type: 'spline',
      zoomType: 'x',
      alignTicks: false
    },
    
    title: {
      text: "Chromatogram"
    },
    xAxis: [{ // xAxe
     
      labels: {
          format: '{value}s',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'Time (s)',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }],
    yAxis: [{ // Primary yAxis
      labels: {
          format: '{value} mV',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'PID Signal',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }, 
    { // Secondary yAxis
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: {
          text: 'Temperature',
          style: {
              color: Highcharts.getOptions().colors[5]
          }
      },
      labels: {
          format: '{value} °C',
          style: {
              color: Highcharts.getOptions().colors[5]
          }
      },
      opposite: true
    },],
  credits: {
    enabled:false
  },
  plotOptions: {
    spline: {
        marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
        }
    },
    areaspline: {
     
      marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
              hover: {
                  enabled: true
              }
          }
      }
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ]
      }
    },
  },
  
  series: [{
      // cambiare nome in PID Signal (mV)
      name: 'PID SIGNAL',
      data: [],
      type: 'spline',
      visible: true,
      color: Highcharts.getOptions().colors[0]
    },{
      name: 'T° PRECONCENTRATOR',
      data: [],
      yAxis: 1,
      type: 'spline',
      visible:  false,
      color: Highcharts.getOptions().colors[5]
    },
    //{
      // DA TOGLIERE !!!!! 
      //name: 'Temppd',
      //data: [1, 2, 3],
      //yAxis: 1,
      //type: 'spline',
      //color: Highcharts.getOptions().colors[7]
    //},
    {
      
      name: 'T° COLUMN',
      data: [],
      yAxis: 1,
      type: 'spline',
      visible: false,
      color: Highcharts.getOptions().colors[8]
    }
  ]}; 
  gasses: object={"71-43-2": "Benzene",	
  "108-88-3":"Toluene",	
  "100-41-4": "Ethylbenzene",	
  "1330-20-7": "Xylenes",
  };
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {   } // optional function, defaults to null
  updateFlag: boolean = false; // optional boolean
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  
  chart: Object;
  saveInstance(chartInstance): void {
      this.chart = chartInstance;
  }

  constructor(private measure: ChromatogramsService, private differs_A: KeyValueDiffers, ) {
    this.differ = differs_A.find({}).create();
   }

  ngOnInit() {
   
  }

  generatePlotData() {
    let series: Array<any>=[];
    console.log("dati completi",this.chromatogramValues);
    this.chromatogramValues.forEach( item => {
      series.push([<number>item["Time"]/1000, <number>item["Value"]*1000]);
    })
    return  series;
  }
  generateTempData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Tempp"]*1]);
    })
    return series;
  }
  generateTempPpdData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Temppd"]*1]);
    })
    return series;
  }
  generateTempPcData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Tempc"]*1]);
    })
    return series;
  }
  ngDoCheck() {
    var changes = this.differ.diff(this.extraOption);
   // var changes_file = this.differ.diff(this.fileName);
 
    if (changes){
      console.log("passaggio di controllo AAA", this.extraOption);
      this.chartOptions.xAxis[0].plotLines=[];
      this.chartOptions.xAxis[0].floor=undefined;
      this.chartOptions.xAxis[0].ceiling=undefined;
      Object.entries(this.extraOption).forEach(([key, compound]) => {
      console.log("passaggio di controllo BBB", compound);
      if (compound['zoomTo']){
        this.chartOptions.xAxis[0].floor=compound["PBS"]-compound["PBW"]-10;
        this.chartOptions.xAxis[0].ceiling=compound["PBE"]+compound["PBW"]+10;
        this.extraOption[key]['zoomTo']=false;
        
      }
      if (compound['deZoom']==true){
        this.chartOptions.xAxis[0].floor=undefined;
        this.chartOptions.xAxis[0].ceiling=undefined;
        
        this.extraOption[key]['deZoom']=false;
        console.log("passaggio di controllo CCC", this.extraOption);
      }
      if (compound['drawLines']){
        console.log('valore',compound["PBS"]-compound["PBW"],compound["PBS"]+compound["PBW"]);
        
        this.chartOptions.xAxis[0].plotLines.push(
          {   
              color: '#FF0000', // Red
              width: 2,
              value: compound["PBS"]-compound["PBW"],
              dashStyle: 'dot',
              zIndex: 3,
              label: {
                text: 'PBS start',
                align: 'right',
                x: -13,
                y:-15,
                verticalAlign: 'bottom'
            }
          },
          {
              color: '#FF0000', // Red
              width: 2,
              value: compound["PBS"]+compound["PBW"],
              dashStyle: 'dot',
              zIndex: 3,
              label: {
                text: 'PBS end',
                align: 'right',
                x: -13,
                y:-15,
                verticalAlign: 'bottom'
            }
          },

          {
              color: '#00ff00', // Red
              width: 2,
              value: compound["PBE"]-compound["PBW"],
              dashStyle: 'dot',
              zIndex: 3,
              label: {
                text: 'PBE start',
                align: 'right',
                x: -13,
                y:-15,
                verticalAlign: 'bottom'
            }
          },
          {
              color: '#00ff00', // Red
              width: 2,
              value: compound["PBE"]+compound["PBW"],
              dashStyle: 'dot',
              zIndex: 3,
              label: {
                text: 'PBE end',
                align: 'right',
                x: -13,
                y:-15,
                verticalAlign: 'bottom'
            }
          },

          {
              color: '#0000ff', // Red
              width: 2,
              value: compound["PMP"]-compound["PMW"],
              dashStyle: 'dot',
              zIndex: 3,
              label: {
                text: 'PMP start',
                align: 'left',
                x: -13
                
            }
          },
          {
              color: '#0000ff', // Red
              width: 2,
              value: compound["PMP"]+compound["PMW"],
              dashStyle: 'dot',
              zIndex: 3,
              label: {
                text: 'PMP end',
                align: 'left',
                x: -13
            }
          },

          );
           
        }
      });
      this.updateFlag=true;
   
    }
  }
  ngOnChanges(){
  
    this.measure.getChromatogram(this.fileName).subscribe(res => {
      this.chromatogramValues = res;
      
      var concentration=this.generatePlotData(); 
      var temperature=this.generateTempData(); 
    
      var tempPc=this.generateTempPcData(); 
      this.chartOptions.title["text"]=this.fileName;
      this.chartOptions.series["0"]["data"]=concentration;
      this.chartOptions.series["1"]["data"]=temperature;
      this.chartOptions.series["2"]["data"]=tempPc;
      this.updateFlag=true;
      
    }); 
  } 
}
