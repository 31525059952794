<h1>Edit/Save new method</h1>
<form class="example-form" novalidate  [formGroup]="form" >
    <mat-card class="form-container">
        <h2>Main Method Parameters</h2>
           
            <mat-form-field appearance="fill" *ngFor="let field of fields">
              
              <mat-label>{{ field.description }}</mat-label>
              <input name="{{ field.name }}" type="{{field.type}}" class="form-control" min="{{field.min}}" max="{{field.max}}"  matInput placeholder="{{field.default}}" required [formControlName]="field.name" >
              <span matSuffix>{{ field.unit }}</span>
              <mat-error *ngIf="hasError(field.name, 'required')">This field is mandatory</mat-error>
              <mat-error *ngIf="hasError(field.name, 'min')">The minimum value is {{field.min}}</mat-error>
              <mat-error *ngIf="hasError(field.name, 'max')">The maximum value is {{field.max}}</mat-error>
            </mat-form-field>
          <h2>Pid Parameters</h2>
           
            <mat-form-field appearance="fill" *ngFor="let field of pidFields">
              
              <mat-label>{{ field.description }}</mat-label>
              <input name="{{ field.name }}" type="{{field.type}}" class="form-control" min="{{field.min}}" max="{{field.max}}"  matInput placeholder="{{field.default}}" required [formControlName]="field.name" >
              <span matSuffix>{{ field.unit }}</span>
              <mat-error *ngIf="hasError(field.name, 'required')">This field is mandatory</mat-error>
              <mat-error *ngIf="hasError(field.name, 'min')">The minimum value is {{field.min}}</mat-error>
              <mat-error *ngIf="hasError(field.name, 'max')">The maximum value is {{field.max}}</mat-error>
            </mat-form-field>
         
        </mat-card>
        <mat-card class="form-container-ramp">
            <h2>Temperature Ramps</h2>

            <div formArrayName="temperature_ramps">
                <mat-card *ngFor="let item of temperatureRamps.controls; let rampIndex=index" [formGroupName]="rampIndex" class="ramp-row">
                
                  <mat-form-field appearance="fill">
                    <mat-label>Time</mat-label>
                    <input name="time" type="numer" class="form-control"  matInput  formControlName="time" >
                    <span matSuffix>s</span>
                    <mat-error *ngIf="hasErrorRamp(rampIndex,'time', 'required')">This field is mandatory</mat-error>
                    <mat-error *ngIf="hasErrorRamp(rampIndex,'time', 'min')">The minimum value is {{rampsMin[rampIndex]}}</mat-error>
                    <mat-error *ngIf="hasErrorRamp(rampIndex,'time', 'max')">The maximum value is </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                      <mat-label>Start Temp</mat-label>
                      <input name="starttemp" type="numer" class="form-control"  matInput  formControlName="starttemp" >
                      <span matSuffix>c</span>
                  </mat-form-field>
                  <mat-form-field appearance="fill">
                      <mat-label>End Temp</mat-label>
                      <input name="endtemp" type="numer" class="form-control"  matInput  formControlName="endtemp" >
                      <span matSuffix>c</span>
                      <mat-error *ngIf="hasErrorRamp(rampIndex,'endtemp', 'required')">This field is mandatory</mat-error>
                    <mat-error *ngIf="hasErrorRamp(rampIndex,'endtemp', 'min')">The minimum value is  {{rampsMin[rampIndex]}} </mat-error>
                    <mat-error *ngIf="hasErrorRamp(rampIndex,'endtemp', 'max')">The maximum value is </mat-error>
                  </mat-form-field>
                  <button *ngIf="rampIndex!=0" mat-mini-fab type="button" (click)="deleteTemperatureRamp(rampIndex)"><mat-icon>delete</mat-icon></button>
                 
                </mat-card>
                <button mat-fab  color="primary" type="button" (click)="addTemperatureRamp()"><mat-icon>add</mat-icon></button>
                
                <mat-card style="margin-top:1rem">
                    <ngx-charts-line-chart
                    [view]="view"
                    class="chart-container"
                    [scheme]="colorScheme"
                    [schemeType]="schemeType"
                    [results]="multi"
                    [animations]="animations"
                    [legend]="showLegend"
                    [legendTitle]="legendTitle"
                    [legendPosition]="legendPosition"
                    [gradient]="gradient"
                    [xAxis]="showXAxis"
                    [yAxis]="showYAxis"
                    [showXAxisLabel]="showXAxisLabel"
                    [showYAxisLabel]="showYAxisLabel"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [autoScale]="autoScale"
                    [xScaleMin]="xScaleMin"
                    [xScaleMax]="xScaleMax"
                    [yScaleMin]="yScaleMin"
                    [yScaleMax]="yScaleMax"
                    [timeline]="timeline"
                    [showGridLines]="showGridLines"
                    [curve]="curve"
                    [rangeFillOpacity]="rangeFillOpacity"
                    [roundDomains]="roundDomains"
                    [tooltipDisabled]="tooltipDisabled"
                    [trimXAxisTicks]="trimXAxisTicks"
                    [trimYAxisTicks]="trimYAxisTicks"
                    [rotateXAxisTicks]="rotateXAxisTicks"
                    [maxXAxisTickLength]="maxXAxisTickLength"
                    [maxYAxisTickLength]="maxYAxisTickLength">
                    </ngx-charts-line-chart>
                    <div style="clear:both"></div>
                </mat-card>
            </div>
        </mat-card>
        <mat-card class="addictional-functions">
            <h2>Functions</h2>
        <mat-card-actions>
            <button   mat-raised-button color="primary" [disabled]="form.invalid" type="button" (click)="saveMethod(form)">
                Save
            </button>
          </mat-card-actions>
        </mat-card>
  </form>
