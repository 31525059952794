import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap, share } from 'rxjs/operators';

import { environment } from '../shared/enviroment';
import { ToastrService } from 'ngx-toastr';
import { MAT_DATEPICKER_SCROLL_STRATEGY } from '@angular/material';
export interface Measures
{
  datam: string;
  measureid: number;
  method:string;
  measuredate:string;
  measuretype:number;
  pointn:number;
  note: string;
  uploaded: number;
  valid: number;
  adddata1: number;
  addvalue1: number;
  adddata2: number;
  addvalue2: number;
  adddata3: number;
  addvalue3: number;
  adddata4: number;
  addvalue4: number;
  chromfile: string;
}

export interface ChromatogramValues
{
  Time:number;
  Value:number;
  Temppd:number;
  Tempc:number;
  Tempp:number;
}

@Injectable({
  providedIn: 'root'
})
export class ChromatogramsService {
  private chromatogramServiceUrl = 'http://'+environment.machineApiUrl;  // URL to web api
  constructor(private http: HttpClient, private toastr: ToastrService) { }
  private checkDate(date,startDate,endDate){
    if ((date>=startDate) && (date<=endDate)){
      return true;
    }
    else return false;
  }
  getList(dateStart?,dateEnd?): Observable<Measures[]>
  {
    this.chromatogramServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.chromatogramServiceUrl+'?GetDailyMeasures').pipe(
      map((item: any) => {
         const model:Array<Measures>= [];
         
         if (item.values){
           item.values[" record"].forEach(it=>{
             console.log("valori giorno",it);
              
            
             let arrDateStart=it['measuredate'].split(' ');
             let arrDate=arrDateStart[0].split('-');
             console.log("array data",arrDate);
             let actualDate=new Date(+arrDate[0],+arrDate[1]-1,+arrDate[2]);
             if (dateStart) {
              if (this.checkDate(actualDate,dateStart,dateEnd)) model.push(it);
             }
             else model.push(it);
            
          
            });
          
         }
         this.toastr.success('Chromatogram Service', 'List Loaded');
         return model;
       }),catchError((e: any) =>{
        this.toastr.error('Chromatogram Service', 'Cannot Load List');
        
        return throwError(e);
      }),
   );
  }

  getListDayMethod(day,method): Observable<Measures[]>
  {
    this.chromatogramServiceUrl = 'http://'+environment.machineApiUrl;
    console.log("Richiesta chroma giorno metodo ",this.chromatogramServiceUrl+'?GetDayMeasures='+day+':'+method)
    return this.http.get(this.chromatogramServiceUrl+'?GetDayMeasures='+day+':'+method).pipe(
      map((item: any) => {
         const model:Array<Measures>= [];
         
         if (item.values){
           item.values[" record"].forEach(it=>{
            //Prova!!!
             //it["measuretype"]=Math.round(Math.random());
             
             model.push(it);
           });
          
         }
         this.toastr.success('Chromatogram Service', 'Day Method List Loaded');
         return model;
       }),catchError((e: any) =>{
        this.toastr.error('Chromatogram Service', 'Cannot Load Day Method List');
        
        return throwError(e);
      }),
   );
  }

  getChromatogram(fileName): Observable<ChromatogramValues[]>
  {
    this.chromatogramServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.chromatogramServiceUrl+'?GetChromaFile='+fileName).pipe(
      map((item: any) => {
         const model:Array<ChromatogramValues>= [];
         
         if (item.values){
           item.values[" record"].forEach(it=>{
             model.push(it);
           });
          
         }
         this.toastr.success('Chromatogram Service', 'Chromatogram Loaded');
         return model;
       }),catchError((e: any) =>{
        this.toastr.error('Chromatogram Service', 'Cannot Load Chroma');
       
        return throwError(e);
      }),
   );  
  }
  getCompoundValues(fileName): Observable<object[]>
  {
    this.chromatogramServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.chromatogramServiceUrl+'?GetSingleMeasure='+fileName).pipe(
      map((item: any) => {
        const model:Array<object>= [];
        console.log("Queste sono le misure:", item);
        if (item.values){
          item.values[" record"].forEach(it=>{
            model.push(it);
          });
        }
        return model;
      })
      )
  }
  getDailyValues(day,compound?): Observable<object[]>
  {
    this.chromatogramServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.chromatogramServiceUrl+'?GetDayMeasuresData='+day).pipe(
      map((item: any) => {
         const model:Array<object>= [];
         
         if (item.values){
           item.values[" record"].forEach(it=>{
            console.log("valori GIORNO",it);
            if (it["measuretype"]=="0"){
              var helper = it["chromfile"]; 
              let rec={};
                if (compound) {
                  if (it["idgasid"]==compound){
                  
                    rec['measuredate_str'] = it['measuredate'];

                    var dateString = rec['measuredate_str'];
                    var dateTimeParts = dateString.split(' ');
                    var timeParts = dateTimeParts[1].split(':');
                    timeParts[0]=+timeParts[0]+2;
                    var dateParts = dateTimeParts[0].split('-');
                    var date;

                date = new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1]);

                rec['measuredate'] =date.getTime(); //1379426880000
                  
              
                    rec['concentration']=it["conc"];
                    model.push(rec);
                  }}
                  else{
                    rec['measuredate_str'] = it['measuredate'];
                    rec['idgasid']=it["idgasid"];
                    var dateString = rec['measuredate_str'];
                    var dateTimeParts = dateString.split(' ');
                    var timeParts = dateTimeParts[1].split(':');
                    var dateParts = dateTimeParts[0].split('-');
                    var date;
                    timeParts[0]=+timeParts[0]+2;
                    date = new Date(dateParts[0], parseInt(dateParts[1], 10) - 1, dateParts[2], timeParts[0], timeParts[1]);

                    rec['measuredate'] =date.getTime(); //1379426880000
                      
                  
                        rec['concentration']=it["conc"];
                        model.push(rec);
                  }
            }
           });
          
         }
         this.toastr.success('Chromatogram Service', 'Daily Values Loaded');
         return model;
       }),catchError((e: any) =>{
        this.toastr.error('Chromatogram Service', 'Cannot Load Daily Values');
       
        return throwError(e);
      }),
   );  
  }
 
  getChromatogramRealtime(fromSample?): Observable<ChromatogramValues[]>
  {
    this.chromatogramServiceUrl = 'http://'+environment.machineApiUrl;
    if (!fromSample) fromSample=0;
    return this.http.get(this.chromatogramServiceUrl+'?GetRTPidData='+fromSample).pipe(
      map((item: any) => {
         const model:Array<ChromatogramValues>= [];
         
         if (item.values){
           item.values["samples"].forEach(it=>{
             model.push(it);
           });
          
         }
         return model;
       }),catchError((e: any) =>{
        
        return throwError(e);
      }),
   );
  }
  
}
