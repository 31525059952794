import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes }  from '@angular/router';
import { SharedModule } from  '../../shared/shared.module';
import { StatusDashboardComponent } from './status-dashboard/status-dashboard.component';
import { CompoundGraphComponent } from './compound-graph/compound-graph.component';
import { MatSlideToggleModule,MatSelectModule, MatDividerModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,MatIconModule,MatCardModule, MatButtonModule,MatDatepickerModule, MAT_DATE_FORMATS,
  MAT_DATE_LOCALE } from '@angular/material';
  import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { HighchartsChartModule } from 'highcharts-angular';
const statusRoutes: Routes = [
  { path: 'status/dashboard',  component: StatusDashboardComponent },
  { path: 'status',  component: StatusDashboardComponent }
];

export const statusRouting = RouterModule.forChild(statusRoutes);

@NgModule({
  declarations: [StatusDashboardComponent, CompoundGraphComponent],
  imports: [
    CommonModule,statusRouting, SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule
    , HighchartsChartModule, MatDatepickerModule, FormsModule, ReactiveFormsModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class StatusModule { }

