import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { environment } from '../../../shared/enviroment';
import { ToastrService } from 'ngx-toastr';
import { StatusService, Status, currentStatus } from '../../../services/status.service';
import { LoginDialogService } from '../../../shared/login-dialog/login-dialog.service'

import {MatTableDataSource} from '@angular/material/table';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

interface singleRow{
  key: string;
  value: FormControl;
}

@Component({
  selector: 'app-settings-dashboard',
  templateUrl: './settings-dashboard.component.html',
  styleUrls: ['./settings-dashboard.component.scss']
})
export class SettingsDashboardComponent implements OnInit {
  form: FormGroup;
  formConfig: FormGroup;
  pixysIp: string;
  login: boolean=false;
  dataPyxis: Date;
  oraPyxis: String;
  dataSource: MatTableDataSource<any>;
  formRow: Array<singleRow>=[];
  displayedColumns: string[] = ['configuration',  'value', 'instruments'];
  configToEdit: string="";
  version: string;
  versionLowLevel:string;
  versionPlugin:string;
  restarting:boolean=false;
  constructor(private toastr: ToastrService, private status: StatusService, private dialogService:LoginDialogService) { }
  
  ngOnInit() {
    this.form = new FormGroup({
      pixysIpField: new FormControl(environment.machineApiUrl),
      pixysDate: new FormControl(this.dataPyxis),
      pixysTime: new FormControl(this.oraPyxis),
 
     });  
    this.status.getDateTime().subscribe(res => {
      console.log("io qui vedo",res);
     
      this.dataPyxis =new Date(res['values']['Year'],res['values']['Month']-1,res['values']['Day']);
      this.oraPyxis= res['values']['Hour'] +":"+ res['values']['Minute'];
      this.form.get('pixysIpField').setValue(environment.machineApiUrl);
      this.form.get('pixysDate').setValue(this.dataPyxis);
      this.form.get('pixysTime').setValue(this.oraPyxis);
        
    }); 
    this.status.getVersion().subscribe(res => {
      console.log("version",res);
      this.version=res;
    });
    this.status.getVersionLowLevel().subscribe(res => {
      console.log("versionLowLevel",res);
      this.versionLowLevel=res;
    });
    this.status.getVersionPlugin().subscribe(res => {
      console.log("versionPlugin",res);
      this.versionPlugin=res;
    });
    this.pixysIp=environment.machineApiUrl;
    console.log("dddd",this.pixysIp)
    this.status.getConfig().subscribe(res => {
      console.log("io qui vedo",res);
     
      this.dataSource = new MatTableDataSource<any>(res['values']);
      
    }); 
    const options = {
      title: 'Attention!',
      message: "This area is restricted to admin access.",
      cancelText: 'CANCEL',
      confirmText: 'LOGIN',
      showCancel: true
    };
    
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.login=true;
      }
      else
      {
        this.login=false;
      }
    });
  }
  ngOnDestroy(){
    this.dialogService.close();
  }
  saveMethod(value){
    console.log("salva");
    console.log(value.value);
    environment.machineApiUrl=value.value["pixysIpField"];
    localStorage.setItem("machineApiUrl",value.value["pixysIpField"]);
    this.toastr.success('Pyxis IP', 'IP changed to '+environment.machineApiUrl);
    let dateTime:object={};
    dateTime['Year']=value.value["pixysDate"].getFullYear();
    dateTime['Month']=value.value["pixysDate"].getMonth()+1;
    dateTime['Day']=value.value["pixysDate"].getDate();
    var res = value.value["pixysTime"].split(":");
    dateTime['Hour']=parseInt(res[0]);
    dateTime['Minute']=parseInt(res[1]);
    
    console.log(value.value["pixysDate"]);
    console.log(dateTime);
    this.status.setDateTime(dateTime).subscribe(res =>{

      this.status.restart().subscribe(res =>{
        this.restarting=true;
      });}
    );

  }
  saveIP(value){
    console.log("salva");
    console.log(value.value);
    environment.machineApiUrl=value.value["pixysIpField"];
    localStorage.setItem("machineApiUrl",value.value["pixysIpField"]);
    this.toastr.success('Pyxis IP', 'IP changed to '+environment.machineApiUrl);
  }



  editMeasure(row){
    //da inserire il controllo errori!!!!
    
    this.formRow=[];
    this.configToEdit=row['key'];
    
    this.formRow.push({key: row['key'], value: new FormControl(row['value'])});
    
    
    console.log(this.formRow);
  }
  saveMeasure(row){
    this.configToEdit=undefined;
    console.log(this.formRow);
    if (this.formRow[0].value.touched){
     
      //row['PBS']=this.formRow[0].value.value;
      console.log("valore cambiato:",this.formRow[0].value.value);
      let config:object={};
      config[row['key']]=this.formRow[0].value.value;
      row['value']=this.formRow[0].value.value;
      this.status.setConfig(config).subscribe(res =>{
           this.toastr.success('Configuration', row['key']+' changed to '+this.formRow[0].value.value);
        }
      );
     
    }
   
    
   
    console.log(this.formRow);
  }

  showEdit(row){
    return this.configToEdit==row['key'];
  }

}
