<h1>Sequencer List</h1>
<div class="container">
<mat-card class="material-list">
<table mat-table [dataSource]="dataSource"  matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
      <td mat-cell *matCellDef="let sequencer"> {{sequencer.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let sequencer"> {{sequencer.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef > Edit </th>
      <td mat-cell *matCellDef="let sequencer"><a routerLink="/method-sequencer-manager/sequencer-edit/{{sequencer.id}}"><mat-icon>edit</mat-icon></a> </td>
    </ng-container>

    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef > Delete </th>
        <td mat-cell *matCellDef="let sequencer"><a (click)="deleteSequencer(sequencer.id)"><mat-icon>delete</mat-icon></a> </td>
      </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-card>
<mat-card class="addictional-functions">
  <h2>Functions</h2>
  <mat-card-actions>
      <button   mat-raised-button color="primary" type="button" (click)="newSequencer()">
          Add New Sequencer
      </button>
    </mat-card-actions>
    <mat-card-actions>
      <button   mat-raised-button color="primary" type="button" (click)="refreshList()">
          Refresh Sequencer List
      </button>
    </mat-card-actions>
</mat-card>
</div>