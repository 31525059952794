<app-panels>
  <app-panel panelTitle="Start Analysis" active="{{active_start_analysis}}" routerLink="/start">
    <app-start-analysis>
    </app-start-analysis>
  </app-panel>
  
</app-panels>



