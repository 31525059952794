import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChromatogramComponent } from './chromatogram/chromatogram.component';
import { ChromatogramDataComponent } from './chromatogram-data/chromatogram-data.component';

import { MeasureListComponent } from './measure-list/measure-list.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSlideToggleModule,MatSelectModule, MatDividerModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,MatIconModule,MatCardModule, MatButtonModule, MatDatepickerModule, MAT_DATE_FORMATS,
  MAT_DATE_LOCALE} from '@angular/material';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';


import { HighchartsChartModule } from 'highcharts-angular';

const calibrationRoutes: Routes = [
  { path: 'calibration',  component: MeasureListComponent },
  { path: 'calibration/measure-list',  component: MeasureListComponent },
];

export const calibrationRouting = RouterModule.forChild(calibrationRoutes);
@NgModule({
 
  imports: [
    CommonModule,
    calibrationRouting,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule, 
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    ReactiveFormsModule, FormsModule, MatButtonModule, RouterModule, HighchartsChartModule, MatDatepickerModule
  ],
  declarations: [ChromatogramComponent, MeasureListComponent,ChromatogramDataComponent],
})
export class CalibrationModule { }
