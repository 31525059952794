import { Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef } from '@angular/core';
 
import { PanelComponent } from '../panel/panel.component';

@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.scss']
})
export class PanelsComponent implements AfterContentInit {

  @ContentChildren(PanelComponent) panels: QueryList<PanelComponent>;
  
  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activePanels = this.panels.filter((panel)=>panel.active);
    
    // if there is no active tab set, activate the first
    if(activePanels.length === 0) {
      this.selectPanel(this.panels.first);
    }
  }
  
  selectPanel(panel: PanelComponent){
    // deactivate all tabs
    this.panels.toArray().forEach(panel => panel.active = false);
    
    // activate the tab the user has clicked on.
    panel.active = true;
  }

}
