import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StartModule } from './pages/start/start.module';
import { StatusModule } from './pages/status/status.module';
import { MethodSequencerManagerModule } from './pages/method-sequencer-manager/method-sequencer-manager.module';
import { DataBrowserModule } from './pages/data-browser/data-browser.module';
import { CalibrationModule } from './pages/calibration/calibration.module';
import { SettingsModule } from './pages/settings/settings.module';


const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full' },
  {
    path: 'start',
    loadChildren: './pages/start/start.module#StartAnalysisComponent' 
  },
  {
    path: 'status',
    loadChildren: './pages/status/status.module#StatusModule'
  },
  {
    path: 'method-sequencer-manager',
    loadChildren: './pages/method-sequencer-manager/method-sequencer-manager.module#MethodListComponent' 
  },
  {
    path: 'data-browser',
    loadChildren: './pages/data-browser/data-browser.module#MeasureListComponent' 
  },
  
  {
    path: 'calibration',
    loadChildren: './pages/calibration/calibration.module#MeasureListComponent' 
  },
  {
    path: 'settings',
    loadChildren: './pages/settings/settings.module#SettingsDashboardComponent' 
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes),MethodSequencerManagerModule,StartModule,StatusModule,DataBrowserModule,SettingsModule,CalibrationModule],
  exports: [RouterModule]
}) 
export class AppRoutingModule { }
