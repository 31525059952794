import { Component, OnInit,  SimpleChanges } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import { ViewChild } from '@angular/core'
import {Router} from '@angular/router';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss']
})
export class MeasureListComponent implements OnInit {

  formSelectDate: FormGroup;
  chromatograName:string;
  showChroma: boolean=false;
  showData: boolean=false;
  dataName:string;
  measures: Array<object>;
  measuresDay: Array<object>;
  displayedColumns: string[] = ['datam',  'select'];
  displayedColumnsDay: string[] = ['measuretype','measuredate', 'method', 'select'];
  dataSource: MatTableDataSource<any>;
  dataSourceDay: MatTableDataSource<any>;
  actualDate: string;
  expandMeasures: boolean=false;
  expandDates: boolean=true;
  startDate: Date = new Date();
  endDate: Date = new Date();
  startDateFormat: String;
  endDateFormat:String;
  measureId: string;
  
  @ViewChild('firstSort') sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('secondSort') sortDay: MatSort;
  @ViewChild('paginatorDay') paginatorDay: MatPaginator;

 

  constructor(private measure: ChromatogramsService, private router:Router, private _formBuilder:FormBuilder,private route: ActivatedRoute) { }
  ngOnInit() {
    this.startDate.setMonth(this.startDate.getMonth()-1);
    this.measure.getList(this.startDate,this.endDate).subscribe(res => {
      console.log("io qui vedo",res);
      this.measures = res;
      this.dataSource = new MatTableDataSource<any>(this.measures);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }); 
    this.dataSourceDay = new MatTableDataSource<any>();
    this.dataSourceDay.paginator = this.paginatorDay;
    this.dataSourceDay.sort = this.sortDay;
    this.formSelectDate = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
    

    this.formSelectDate.get('startDate').setValue(this.startDate);
    this.formSelectDate.get('endDate').setValue(this.endDate);
    this.onChanges();
    //controllo se modifica o creazione
    this.measureId=this.route.snapshot.paramMap.get('id');
    if (this.measureId) {
      this.loadChromatogram(this.measureId)
    }
  }
  
  onChanges() {
    this.formSelectDate.valueChanges.subscribe(val => {
      this.startDate=val.startDate;
      this.endDate= val.endDate;

      this.measure.getList(this.startDate,this.endDate).subscribe(res => {
        console.log("io qui vedo",res);
        this.measures = res;
        this.dataSource = new MatTableDataSource<any>(this.measures);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }); 
    });
  }
  loadDay(day,method){
    console.log("ha caricato il Giorno",day,method);
    this.measure.getListDayMethod(day.substr(0,10),method).subscribe(res => {
      console.log("io qui vedo",res);
      this.measuresDay = res;
      this.dataSourceDay = new MatTableDataSource<any>(this.measuresDay);
      this.dataSourceDay.paginator = this.paginatorDay;
      this.dataSourceDay.sort = this.sortDay;
      this.expandMeasures=true;
      this.expandDates=false;
      this.actualDate=day.substr(0,10);
     
    }); 
  }
  openDates(){
    this.expandMeasures=false;
  }

  loadChromatogram(fileName){
    console.log("ha caricato il chroma",fileName);
    this.chromatograName=fileName;
    this.showChroma=true;
    this.showData=false;
  }
  loadData(fileName){
    console.log("ha caricato i dati",fileName);
    this.dataName=fileName;
    this.showChroma=false;
    this.showData=true;
  }

}
