import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { MethodService } from '../../../services/method.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-method-list',
  templateUrl: './method-list.component.html',
  styleUrls: ['./method-list.component.scss']
})
export class MethodListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  methods: Array<object>;

  constructor( private method: MethodService, private router:Router) { }

  ngOnInit() {
    this.method.getList().subscribe(res => {
      console.log("io qui vedo",res);
      this.methods = res;
      this.dataSource = new MatTableDataSource<any>(this.methods);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }); 
    this.dataSource = new MatTableDataSource<any>(this.methods);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  newMethod() {
    this.router.navigate(['/method-sequencer-manager/method-edit']);
  }
  refreshList() {
    this.method.getList().subscribe(res => {
      this.methods = res;
    });
    this.dataSource = new MatTableDataSource<any>(this.methods);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log("Method list refreshed");
  }
  deleteMethod(id){
    console.log("Cancellato "+id);
  }
}
