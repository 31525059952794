import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from '../../../services/logger.service';

@Component({
  selector: 'app-method-sequencer-dashboard',
  templateUrl: './method-sequencer-dashboard.component.html',
  styleUrls: ['./method-sequencer-dashboard.component.scss']
})
export class MethodSequencerDashboardComponent implements OnInit {
  active_method_edit: string;
  active_sequencer_edit: string;
  active_method_list: string;
  active_sequencer_list: string;
  

  constructor(private route: ActivatedRoute,private logger: LoggerService) { }

  ngOnInit() {
    
    if(this.route.snapshot.url[1]!==undefined){
    switch (this.route.snapshot.url[1].path) {
      case "method-edit":
        this.active_method_edit="true";
        break;
      case "sequencer-edit":
        this.active_sequencer_edit="true";
        break;
      case "method-list":
        this.active_method_list="true";
        break;
      case "sequencer-list":
        this.active_sequencer_list="true";
        break;
    }
  }
}

}
