import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { StatusService, Status, currentStatus } from '../../../services/status.service';
@Component({
  selector: 'app-start-dashboard',
  templateUrl: './start-dashboard.component.html',
  styleUrls: ['./start-dashboard.component.scss']
})
export class StartDashboardComponent implements OnInit {
  private subscriptions: Subscription[]=[];
  active_start_analysis: string;
  active_chromatogram: string;
  active_report: string;
  status: currentStatus={method:'Waiting...', status:'idle'};
  constructor(private route: ActivatedRoute,private statusService:StatusService,) { }

  ngOnInit() {
    this.subscriptions.push(interval(10000).subscribe((value: number) => {
      this.statusService.getStatus().subscribe(res => {
        console.log("Ecco il res",res);
        this.status = res;
        //inizializzo l'interfaccia a seconda dello stato
        console.log(this.status);
      })
    }));
    if(this.route.snapshot.url[1]!==undefined){
      switch (this.route.snapshot.url[1].path) {
        case "start-analysis":
          this.active_start_analysis="true";
          break;
        case "chromatogram":
          this.active_chromatogram="true";
          break;
        case "report":
          this.active_report="true";
          break;
     
      }
    }

  }

}


