<app-panels>
  <app-panel panelTitle="Chromatogram History" active="true">
    
<div class="container">



<mat-card class="material-list">
  <form class="form" novalidate  [formGroup]="formSelectDate" >
  <mat-expansion-panel class="selectDatesPanel">
    <mat-expansion-panel-header> 
      <mat-panel-title>Select Date Period <span style="font-weight:normal">&nbsp; from <em>{{startDate.toLocaleDateString()}}</em> to <em>{{endDate.toLocaleDateString()}}</em></span></mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field  appearance="fill">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="picker_start_date" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
      <mat-datepicker #picker_start_date></mat-datepicker>
    </mat-form-field>&nbsp;
    <mat-form-field  appearance="fill">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="picker_end_date" formControlName="endDate">
      <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
      <mat-datepicker #picker_end_date></mat-datepicker>
    </mat-form-field>
  </mat-expansion-panel>
</form>
<br />
  <mat-expansion-panel [(expanded)]="expandDates" (opened)="openDates()" class="datesPanel">
    <mat-expansion-panel-header>
      <mat-panel-title>Select Day</mat-panel-title>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="dataSource"  #firstSort="matSort"  matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="datam">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let measure"> {{measure.datam| date:"dd/MM/yyyy"}} </td>
    </ng-container>

   

    <!-- Weight Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef style="text-align: center" > Show Daily Measures </th>
      <td mat-cell *matCellDef="let measure" style="text-align: center"><a (click)="loadDay(measure.measuredate,measure.method)" style="cursor:pointer"><mat-icon >search</mat-icon></a> </td>
    </ng-container>

    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

<mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-expansion-panel>
<br />
<mat-expansion-panel [(expanded)]="expandMeasures" class="measuresPanel" >
  <mat-expansion-panel-header >
    <mat-panel-title>Select Measure</mat-panel-title>
    <mat-panel-description><span *ngIf="actualDate">on {{actualDate| date:"dd/MM/yyyy"}}</span></mat-panel-description>
  </mat-expansion-panel-header>

  <table mat-table [dataSource]="dataSourceDay" #secondSort="matSort"  matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="measuretype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let measure"> <mat-icon *ngIf="measure.measuretype=='0'" style="color:green">show_chart</mat-icon> <mat-icon style="color:red" *ngIf="measure.measuretype=='1'">graphic_eq</mat-icon> </td>
      </ng-container>

      <ng-container matColumnDef="measuredate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let measure"> {{measure.measuredate| date:"HH:mm"}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Method </th>
        <td mat-cell *matCellDef="let measure"> {{measure.method}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="text-align: center" > Show Chromatogram </th>
        <td mat-cell *matCellDef="let measure" style="text-align: center"><a (click)="loadChromatogram(measure.measureid)" style="cursor:pointer"><mat-icon >timeline</mat-icon></a> </td>
      </ng-container>
  
      
  
      <tr mat-header-row *matHeaderRowDef="displayedColumnsDay"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsDay;"></tr>
  </table>
  <mat-paginator #paginatorDay="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-expansion-panel>
</mat-card>
<mat-card class="chromatogram"   *ngIf="showChroma" >

  <app-chromatogram [fileName]="chromatograName"  ></app-chromatogram>
</mat-card>
<mat-card class="chromatogram"   *ngIf="showData" >

  <app-chromatogram-data [fileName]="dataName"  ></app-chromatogram-data>
</mat-card>
</div>
</app-panel>
</app-panels>