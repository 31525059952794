import { Component, OnInit } from '@angular/core';
import { Pagelink } from '../../classes/pagelink';


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  
})

export class TopMenuComponent implements OnInit {
  pages = [
    new Pagelink('start', 'Start'),
    new Pagelink('status', 'Trends'),
  // new Pagelink('method-sequencer-manager', 'Method/Sequencer Manager'),
    new Pagelink('data-browser', 'Data Browser'),
  //  new Pagelink('planner', 'Planner'),
    new Pagelink('calibration', 'Calibration'),
    new Pagelink('settings', 'Settings'),
  ];
  constructor() { 
    
  }

  ngOnInit() {
    
  }

}
