import { Component, OnInit,ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { SequencerService } from '../../../services/sequencer.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sequencer-list',
  templateUrl: './sequencer-list.component.html',
  styleUrls: ['./sequencer-list.component.scss']
})
export class SequencerListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sequencers: Array<object>;

  constructor( private sequencer: SequencerService, private router:Router) { }

  ngOnInit() {
    this.sequencer.getList().subscribe(res => {
      this.sequencers = res;
    });
    this.dataSource = new MatTableDataSource<any>(this.sequencers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  newSequencer() {
    this.router.navigate(['/method-sequencer-manager/sequencer-edit']);
  }
  refreshList() {
    this.sequencer.getList().subscribe(res => {
      this.sequencers = res;
    });
    this.dataSource = new MatTableDataSource<any>(this.sequencers);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log("Sequencer list refreshed");
  }
  deleteSequencer(id){
    console.log("Cancellato "+id);
  }
}

