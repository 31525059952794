import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChromatogramComponent } from './chromatogram/chromatogram.component';
import { ChromatogramDataComponent } from './chromatogram-data/chromatogram-data.component';

import { MeasureListComponent } from './measure-list/measure-list.component';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSlideToggleModule,MatSelectModule, MatDividerModule, MatExpansionModule, 
  MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,MatIconModule,MatCardModule, MatButtonModule, 
  MatDatepickerModule, MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgxChartsModule} from '@swimlane/ngx-charts';

import { HighchartsChartModule } from 'highcharts-angular';

const dataBrowserRoutes: Routes = [
  { path: 'data-browser',  component: MeasureListComponent },
  { path: 'data-browser/measure-list',  component: MeasureListComponent },
  { path: 'data-browser/id/:id',  component: MeasureListComponent },
];
export const dataBrowserRouting = RouterModule.forChild(dataBrowserRoutes);
@NgModule({
 
  imports: [
    CommonModule,
    dataBrowserRouting,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule, FormsModule, MatButtonModule, RouterModule, HighchartsChartModule, MatDatepickerModule
  ],
  declarations: [ChromatogramComponent, MeasureListComponent,ChromatogramDataComponent],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class DataBrowserModule { }
