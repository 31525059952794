
<nav role='navigation' >
  <ul class="main" fxShow="true" fxHide.lt-md="true">
    <li *ngFor="let page of pages">
      <a routerLink="/{{ page.link }}" routerLinkActive="current">{{ page.name }}</a>
    </li>
  </ul>
  <div fxShow="true" fxHide.gt-sm="true" #open>
    <a style="width:3rem; position:absolute; top:.3rem; right:.5rem; " (click)="sidenav.toggle();open.toggle()"><mat-icon>menu</mat-icon></a>
  </div>
</nav>
<mat-sidenav #sidenav fxLayout="column" class="menu-mobile">
  <div fxLayout="column">
    <a style="width:3rem; position:absolute; top:.3rem; right:.5rem;" (click)="sidenav.toggle();open.toggle()"  mat-button><mat-icon>close</mat-icon></a>
    <ul class="main" >
      <li *ngFor="let page of pages">
        <a routerLink="/{{ page.link }}" routerLinkActive="current">{{ page.name }}</a>
      </li>
    </ul>
  </div>
</mat-sidenav>
 