import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
  @Input() panelTitle: string;
  @Input() active = false;
  @Input() routerLink: string;
  constructor() { }

  ngOnInit() {
  }

}
