import { Injectable } from '@angular/core';
import { of } from 'rxjs';
export interface PlannerValues
{
  name: string;
  id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PlannerService {
  planners:Array<PlannerValues>= [
  { name: 'Planner A', 
    id: 0},
  { name: 'Planner B', 
    id: 1},
  { name: 'Planner C', 
    id: 2},
  { name: 'Planner D', 
    id: 3},
];
  
  constructor() { }
  getList()
  {
    return of(this.planners);
  }
}
