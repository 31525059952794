import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TopMenuComponent } from './partials/top-menu/top-menu.component';
import { FooterComponent } from './partials/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { DisableControlDirective } from './directives/disable-control.directive';
import { HttpClientModule } from '@angular/common/http'; 
import { HighchartsChartModule } from 'highcharts-angular';
import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule, MatButtonModule, MatToolbarModule, MatIconModule, MatInputModule } from '@angular/material';
//Some import before, add this
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material';
import {
  MomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
export const MY_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    TopMenuComponent,
    FooterComponent,
    DisableControlDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    HttpClientModule,
    HighchartsChartModule,
    FlexLayoutModule, MatSidenavModule, MatToolbarModule, MatIconModule, MatInputModule,
    ToastrModule.forRoot({timeOut: 3000,
      positionClass: 'toast-bottom-right',
      progressBar: true})
  ],
  providers: [{
    provide: MAT_DATE_LOCALE,
    useValue: 'it'
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
