import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../shared/enviroment';

import { LoggerService } from './logger.service';
import { arrayMax } from 'highcharts';
export interface MethodFields {
  default?: number;
  name: string;
  unit?: string;
  min?: number;
  max?: number;
  numchar?: number;
  description: string;
  type?:string;
  methodmod?:string;
  methodsect?:string;
  valadmit?:string;
}
export interface RemoteMethods{
  status: string;
  desc: string;
  values: RemoteMethodsValues; 
}
export interface RemoteMethodsValues{
  methods: Array<string>;
}
export interface BasicParameters
{
  configparam: ConfigParam;
}
export interface TemperatureRamp
{
  numstep: number;
  stepramps: Array<TemperatureRamps>;
}
export interface TemperatureRamps {
  time: number;
  starttemp: number;
  endtemp: number;
}
export interface MethodValues
{
  name: string;
  id?: number;
  peaksum?: number;
  pcenable?: number,
  calorificvrange?: string;
  basicparameters?: BasicParameters;
  temperatureramp?: TemperatureRamp;
  pipedparameters?: object;
}

export interface CalibrationValues
{
  name: string;
  peak?: Array<object>;
}

export interface ConfigParam {
  name: string;
  totcycletime?: number;
	basetemp?: number;
  manifoldtemp?: number;
  preheatpreconctemp?: number;
  preheatpreconctime?: number;
  heatingtime?: number; 
  injectiontime?: number;
  startrampdelay?: number;
  cleaningdelay?: number;
  endsamplingtime?: number;
}
@Injectable({
  providedIn: 'root'
})

export class MethodService {
  private methodServiceUrl = 'http://'+environment.machineApiUrl;  // URL to web api
  methods: Array<MethodValues> = [];

  returnStructure: Array<object> =[ 
    {
    "@name": "totcycletime",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "tempo totale di un ciclo di misura",
    "default": "900",
    "min": "300",
    "max": "1800",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "finalstabilizationtime",
    "description": "tempo di stabilizzazione alla fine della ricetta",
    "default": "20",
    "min": "1",
    "max": "100",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "basetemp",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "temperatura base",
    "default": "50",
    "min": "0",
    "max": "100",
    "valadmit": "",
    "ndecimal": "1",
    "unit": "C",
    "numchar": "3"
    },
    
    {
    "@name": "manifoldtemp",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "temperatura manifold (differenza dalla temperatura base)",
    "default": "45",
    "min": "0",
    "max": "50",
    "valadmit": "",
    "ndecimal": "1",
    "unit": "C",
    "numchar": "3"
    },
    
    {
    "@name": "precondesorptiontemp",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "temperatura desorbimento preconcentratore",
    "default": "111",
    "min": "0",
    "max": "200",
    "valadmit": "",
    "ndecimal": "1",
    "unit": "C",
    "numchar": "3"
    },
    
    {
    "@name": "preheatingtime",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "tempo preriscaldamento preconcentratore",
    "default": "6",
    "min": "0",
    "max": "200",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "injectiontime",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "tempo di iniezione",
    "default": "30",
    "min": "0",
    "max": "200",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "startrampdelay",
    "description": "delay fra iniezione e inizio rampa (",
    "default": "4",
    "min": "0",
    "max": "200",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "cleaningdelay",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "tempo di inizio pulizia (dopo l'iniezione)",
    "default": "210",
    "min": "0",
    "max": "500",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "pidsamplingstartime",
    "description": "tempo inizio fase di campionamento",
    "default": "20",
    "min": "0",
    "max": "200",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "pidsamplingstartdelay",
    "@methodmod": "true",
    "@methodsect": "pidparameters",
    "description": "delay di inizio acquisizione PID dopo inizio campionamento",
    "default": "10",
    "min": "0",
    "max": "1000",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "pidsamplingtime",
    "@methodmod": "true",
    "@methodsect": "pidparameters",
    "description": "durata campionamento da PID",
    "default": "500",
    "min": "0",
    "max": "1800",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "pidsamplinginterval",
    "@methodmod": "true",
    "@methodsect": "pidparameters",
    "description": "frequenza di campionamento del PID (millisecondi)",
    "default": "500",
    "min": "200",
    "max": "10000",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "0"
    },
    
    {
    "@name": "pidgain",
    "@methodmod": "true",
    "@methodsect": "pidparameters",
    "description": "gain amplificatore PID (valori ammessi 1,2,4,8)",
    "default": "4",
    "min": "1",
    "max": "8",
    "valadmit": "1,2,4,8",
    "ndecimal": "0",
    "unit": "G",
    "numchar": "1"
    },
    
    {
    "@name": "samplingvolume",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "volume campionato",
    "default": "220",
    "min": "1",
    "max": "5000",
    "valadmit": "",
    "ndecimal": "2",
    "unit": "SCCM",
    "numchar": "4"
    },
    
    {
    "@name": "rampstabilization",
    "@methodmod": "true",
    "@methodsect": "configparam",
    "description": "tempo temperatura costante alla fine della rampa",
    "default": "379",
    "min": "1",
    "max": "1800",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "4"
    },
    
    {
    "@name": "flowsensorstartdelay",
    "description": "tempo di attesa tra il fine cleaning e l'inizio rilevamento flussi",
    "default": "10",
    "min": "0",
    "max": "50",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "S",
    "numchar": "2"
    },
    
    {
    "@name": "flowsensorfactorprec",
    "description": "valore di flusso sensore Preconcentratore (da dividere per 1000 per avere il float)",
    "default": "1875000",
    "min": "1",
    "max": "5000000",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "SCCM",
    "numchar": "8"
    },
    
    {
    "@name": "flowsensorfactorgc",
    "description": "valore di flusso sensore GC (da dividere per 1000 per avere il float)",
    "default": "300000",
    "min": "1",
    "max": "5000000",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "SCCM",
    "numchar": "8"
    },
    
    {
    "@name": "flowsensorfactorcalib",
    "description": "valore di flusso sensore Calibrazione (da dividere per 1000 per avere il float)",
    "default": "250000",
    "min": "1",
    "max": "5000000",
    "valadmit": "",
    "ndecimal": "0",
    "unit": "SCCM",
    "numchar": "8"
    }];
  methodStructure: Array<MethodFields> = [
    {
      name: 'name',
      description: "Nome del metodo",
    },
   ];
  methodPidStructure: Array<MethodFields> =[];
  constructor( private logger:LoggerService,private http: HttpClient) { }
  
  getList(): Observable<MethodValues[]> {
    this.methodServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.methodServiceUrl+'?GetMethodsList').pipe(
       map((item: any) => {
          const model:Array<MethodValues>= [];
          if (item.values){
            item.values.methods.forEach(it=>{
              model.push({name:it});
            });
          }
          return model;
        })
    );
 }
  getMethod(name: string): Observable<MethodValues>
  {
    this.methodServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.methodServiceUrl+'?GetMethod='+name).pipe(
      map((item: any) => {
         const model:MethodValues= {name:name};
        
         model.basicparameters=item.values.method.baseparameters;
         
         model.basicparameters.configparam.name=name;
         model.temperatureramp=item.values.method.baseparameters.temperatureramp;
         // FORZATURA VA CORRETTO IL MODELLO CHE TORNA LA MACCHINA
         model.temperatureramp.numstep=item.values.method.baseparameters.temperatureramp['@numstep'];
         if (model.temperatureramp.numstep==1) 
          model.temperatureramp.stepramps=[item.values.method.baseparameters.temperatureramp.stepramp];
         else 
          model.temperatureramp.stepramps=item.values.method.baseparameters.temperatureramp.stepramp;
        console.log("item",item);
         return model;
       })
   );
    //return of(this.methods[id]);
  }

  getCalibration(name:string): Observable<CalibrationValues>
  {
    this.methodServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.methodServiceUrl+'?GetMethod='+name).pipe(
      map((item: any) => {
         const model:CalibrationValues= {name:name,peak:[]};
         console.log("tesssst",item); 
         //model.peak=item.values.method.peak;
         let i=0;
         let compounds: Array<string>=['Benzene','Toluene','Ethylbenzene','Xylene'];
         item.values.method.peak.forEach (it =>{
           console.log(it);
            const item:object=[];
            item["compound"]=it["param"]["gasname"];
            item["gasid"]=it["param"]["gasid"];
            item["PBS"]=it["calibration"]["rtstart"]/1000;
            item["PBE"]=it["calibration"]["rtend"]/1000;
            item["PMP"]=it["calibration"]["rtheight"]/1000;
            item["PBW"]=it["calibration"]["rtstartth"]/1000;
            item["PMW"]=it["calibration"]["rtheightth"]/1000;
            if (it["calibration"]["calibpoints"]["pointc"] instanceof Array) {
              item["concentration"]=it["calibration"]["calibpoints"]["pointc"][0]["concentration"];
            }
            else {
              item["concentration"]=it["calibration"]["calibpoints"]["pointc"]["concentration"];
            }
            model.peak.push(item);
            i++;
         });
         console.log (model);
         return model;
       })
    );
  }

  getCalibrationRaw(name:string): Observable<any>
  {
    this.methodServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.methodServiceUrl+'?GetMethod='+name).pipe(
      map((item: any) => {
         const model= {name:name,peak:[]};
        
         item.values.method.peak.forEach (it =>{
           console.log(it);
            const item:object=[];
            item["compound"]=it["param"]["gasname"];
            item["calibration"]=it["calibration"];
           
            model.peak.push(item);
           
         });
         console.log (model);
         return model;
       })
    );
  }

  putMethod( method: MethodValues)
  {
    this.logger.log("Metodo salvato");
    this.logger.log(method);
    this.methodServiceUrl = 'http://'+environment.machineApiUrl;
    return this.http.get(this.methodServiceUrl+'?SetMethod='+method).pipe(
      map((item: any) => {
        console.log(this.methodServiceUrl+'?SetMethod='+method);
        return item;
      }));
    
  }
  newMethod( method: MethodValues)
  {
    this.logger.log("Metodo salvato");
    this.logger.log(method);
    return of(true);
  }
  
  updateMethod( method: MethodValues)
  {
    this.logger.log("Metodo salvato");
    this.logger.log(method);
    this.methodServiceUrl = 'http://'+environment.machineApiUrl;

    // Note: cache should not be re-used by repeated calls to JSON.stringify.
    var cache = [];
    var strmeth=JSON.stringify(method, function(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // Duplicate reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });
    cache = null; // Enable garbage collection
    console.log("method",method);
    return this.http.get(this.methodServiceUrl+'?SetMethod='+strmeth).pipe(
      map((item: any) => {
        
        return item;
      }));
  }
  getMethodStructure()
  {
    this.methodStructure=[
      {
        name: 'name',
        description: "Nome del metodo",
      },
     ];
    this.returnStructure.forEach( item=>{
      if (item["@methodmod"]==="true"){
        switch (item["@methodsect"]){
          case "configparam":
              this.methodStructure.push({
                name:item["@name"], 
                description:item["description"],
                methodmod: item["@methodmod"],
                methodsect: item["@methodsect"],
                default: item["default"],
                min: item["min"],
                max: item["max"],
                valadmit: item["valdadmit"],
                unit: item["unit"]
              });
            break;
        }
      
    }
    });
    return of(this.methodStructure);
  }
  getPidMethodStructure()
  {
    this.methodPidStructure=[];
    this.returnStructure.forEach( item=>{
      if (item["@methodmod"]==="true"){
        switch (item["@methodsect"]){
          case "pidparameters":
              this.methodPidStructure.push({
                name:item["@name"], 
                description:item["description"],
                methodmod: item["@methodmod"],
                methodsect: item["@methodsect"],
                default: item["default"],
                min: item["min"],
                max: item["max"],
                valadmit: item["valdadmit"],
                unit: item["unit"]
              });
            break;
        }
      
    }
    });
    return of(this.methodPidStructure);
  }
   /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
