import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelsComponent } from './panels/panels.component';
import { PanelComponent } from './panel/panel.component';
import { RouterModule, Routes, Router } from '@angular/router';
import { ChromatogramGraphComponent } from './chromatogram-graph/chromatogram-graph.component';
import { ComboSeriesVerticalComponent } from './chromatogram-graph/combo-series-vertical.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';
import { MatDialogModule, MatButtonModule,MatFormFieldModule,MatInputModule } from '@angular/material';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { LoginDialogService } from './login-dialog/login-dialog.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [PanelsComponent, PanelComponent, ChromatogramGraphComponent, ComboSeriesVerticalComponent,ConfirmDialogComponent,LoginDialogComponent],
  imports: [
    CommonModule, RouterModule, NgxChartsModule,
    MatDialogModule,
    MatButtonModule, 
    MatFormFieldModule,FormsModule,ReactiveFormsModule,MatInputModule  ],
  exports: [PanelsComponent,PanelComponent, ChromatogramGraphComponent, ComboSeriesVerticalComponent,ConfirmDialogComponent,LoginDialogComponent],
  entryComponents: [ConfirmDialogComponent,LoginDialogComponent],
    providers: [ConfirmDialogService,LoginDialogService]
})
export class SharedModule { }
