import { Component, OnInit } from '@angular/core';
import { SequencerService, SequencerValues, SequencerStep, SequencerAlarm } from '../../../services/sequencer.service';
import { CalibrationService, CalibrationValues} from '../../../services/calibration.service';
import {FormBuilder, FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MethodService } from '../../../services/method.service';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-sequencer-edit',
  templateUrl: './sequencer-edit.component.html',
  styleUrls: ['./sequencer-edit.component.scss']
})
export class SequencerEditComponent implements OnInit {
  form: FormGroup;
  id:string;
  values:SequencerValues;
  alarmsOn: Array<boolean>=[];
  methodAList: Array<object>;
  calibrations:Array<CalibrationValues>;
  constructor(private calibration:CalibrationService, private method:MethodService, private sequencer: SequencerService ,private fb: FormBuilder,private route: ActivatedRoute, private location: Location,private router:Router) { }

  ngOnInit() {

    this.id=this.route.snapshot.paramMap.get('id');
    this.method.getList().subscribe(res => {
      this.methodAList = res;
    });
    this.calibration.getList().subscribe(res => {
      this.calibrations = res;
    });
    this.route.paramMap.subscribe(params => {
      console.log("Ecco i parametri");
      console.log(params);
    });
    if (this.id){
      this.sequencer.getSequencer(+this.id).subscribe(res => {
        if (res!==undefined)
        {
          this.values=res;
        }
      });
    }
  
      this.form = this.fb.group({
        sequencerDetails: this.fb.group({
          name: [null, Validators.compose([Validators.required])],
        }),
        sequencerSteps: this.fb.array([])
      });
      //this.alarmsOn.push(false);
      if (this.values!==undefined){
        this.populateSequencer();
      }
    
  }

  populateSequencer(){
    if (this.values!==undefined){
    let sequencerDetails= this.form.get("sequencerDetails");
    sequencerDetails.get("name").setValue(this.values.name);
    let index=0;
  
    this.values.steps.forEach(step=>{
      if (step.alarmOn){
      let seqStep=this.loadSequencerStep({id:index,
        delay: step.delay,
        method: step.method,
        number: null,
        onAlarmAction: this.fb.array([]),
        alarms: this.fb.array([]),
        point: String(step.point),
        rptOnAlarm: null,
        rpt: step.rpt,
        alarmOn: true
      });
      step.alarms.forEach(alarm=>{
          (seqStep.get("alarms")as FormArray).push(this.fb.group({
            gasName: [{value:alarm.gasName, disabled:true}],
            calibrationValue: [{value:alarm.calibrationValue, disabled:true}],
            lowValue: [alarm.lowValue, Validators.compose([Validators.required])],
            highValue: [alarm.highValue, Validators.compose([Validators.required])],})
            );
      });

      step.onAlarmAction.forEach(alarmAction=>{
        (seqStep.get("onAlarmAction")as FormArray).push(this.fb.group({
          point:[String(alarmAction.point), Validators.compose([Validators.required])],
          rpt: [alarmAction.rpt, Validators.compose([Validators.required])],
          method: [alarmAction.method, Validators.compose([Validators.required])],
          delay: [alarmAction.delay, Validators.compose([Validators.required])],
          })
          );
      });

      } else {
        this.loadSequencerStep({id:index,
          delay: step.delay,
          method: step.method,
          number: null,
          onAlarmAction: this.fb.array([]),
          alarms: this.fb.array([]),
          point: String(step.point),
          rptOnAlarm: null,
          rpt: step.rpt,
          alarmOn: false
        });
      }
      index++;
    });
    
  }
  }

  loadSequencerStep(step){
    console.log(step);
    if (step.alarmOn){
      this.alarmsOn.push(true);
      step.alarmOn=true;
      (this.form.get("sequencerSteps") as FormArray).push(this.fb.group(step));
   
    }
    else{
      this.alarmsOn.push(false);
      step.alarmOn=false;
     (this.form.get("sequencerSteps") as FormArray).push(this.fb.group(step));
    }
    return (this.form.get("sequencerSteps") as FormArray).at((this.form.get("sequencerSteps") as FormArray).length-1);
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.form.controls[controlName].hasError(errorName);
  }


  toFormGroup( ) {
    let group: any = {};
    if(this.values===undefined){
      group['name'] =  new FormControl('' || '', [Validators.required]);
  
    }
    else
    {
      group['name'] =  new FormControl(this.values.name || '', [Validators.required]);
    }
    return new FormGroup(group);

  }

  get sequencerSteps():FormGroup {
    
    return this.fb.group({
      number: [null],
      point: [null, Validators.compose([Validators.required])],
      rpt: [null, Validators.compose([Validators.required])],
      method: [null, Validators.compose([Validators.required])],
      delay: [null, Validators.compose([Validators.required])],
      alarmOn: [false],
      repeatOnAlarm: [null],
      alarms: this.fb.array([]),
      onAlarmAction: this.fb.array([])
    }); 
  }

  get alarms(): Array<FormGroup> {
    let alarm: Array<FormGroup>=[];
    this.calibrations.forEach(calibration => {
    alarm.push(this.fb.group({
      gasName: [{value:calibration.name, disabled:true}],
      calibrationValue: [{value:calibration.calibrationValue, disabled:true}],
      lowValue: [null, Validators.compose([Validators.required])],
      highValue: [null, Validators.compose([Validators.required])],
    }));
  });
    return alarm;
  }

  get alarmSteps(): FormGroup {
    return this.fb.group({
      point:[null, Validators.compose([Validators.required])],
      rpt: [null, Validators.compose([Validators.required])],
      method: [null, Validators.compose([Validators.required])],
      delay: [null, Validators.compose([Validators.required])],
    });
  }
  addSequencerStep() {
    this.alarmsOn.push(false);
    (this.form.get("sequencerSteps") as FormArray).push(this.sequencerSteps);
  }
  onSequenceAlarm(ob: MatSlideToggleChange,index) {
    if (this.alarmsOn[index])
    {
      let item = (this.form.get("sequencerSteps") as FormArray).at(index);
      
      while ((item.get("alarms") as FormArray).length) {
        (item.get("alarms") as FormArray).removeAt(0);
      }
      while ((item.get("onAlarmAction") as FormArray).length) {
        (item.get("onAlarmAction") as FormArray).removeAt(0);
      }
    
    }
    else
    {
      
      let item = (this.form.get("sequencerSteps") as FormArray).at(index) as FormGroup;
      this.alarms.forEach(alarm =>{
        (item.get("alarms") as FormArray).push(alarm);
      });
      (item.get("onAlarmAction") as FormArray).push(this.alarmSteps);
    }
    this.alarmsOn[index]= !this.alarmsOn[index];

  }
  deleteSequencerStep(index) {
    this.alarmsOn.splice(index, 1);
    (this.form.get("sequencerSteps") as FormArray).removeAt(index);
  }
  addAlarm(sequencerStep){
    sequencerStep.get("alarms").push(this.alarms);
  }
  deleteAlarm(sequencerStep,index){
    sequencerStep.get("alarms").removeAt(index);
  }

  addAlarmStep(sequencerStep){
    sequencerStep.get("onAlarmAction").push(this.alarmSteps);
  }
  deleteAlarmStep(sequencerStep,index){
    sequencerStep.get("onAlarmAction").removeAt(index);
  }


  // salvataggio del metodo
  saveSequencer(value){
    console.log("salva");
    console.log(value.value);
    this.sequencer.updateSequencer(value).subscribe(() => this.goBack()); 
  }
  goBack(): void {
    this.router.navigate(['/method-sequencer-manager/sequencer-list']);
  }
  

}




