import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatNativeDateModule,  MatSlideToggleModule,MatSelectModule, MatDividerModule, MatExpansionModule, 
  MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,MatIconModule,MatCardModule, MatButtonModule, MatDatepickerModule } from '@angular/material';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SettingsDashboardComponent } from './settings-dashboard/settings-dashboard.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

const settingsRoutes: Routes = [
  { path: 'settings',  component: SettingsDashboardComponent },
  { path: 'settings/dashboard',  component: SettingsDashboardComponent },
];
export const settingsRouting = RouterModule.forChild(settingsRoutes);
@NgModule({
  
  imports: [
    CommonModule,
    settingsRouting,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDialogModule,
    ReactiveFormsModule, FormsModule, MatButtonModule, RouterModule, 
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule
   
  ],
  declarations: [SettingsDashboardComponent],
})
export class SettingsModule { }
