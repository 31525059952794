import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../shared/enviroment';

import { LoggerService } from './logger.service';

export interface CalibrationValues
{
  name: string;
  calibrationValue: number;
}

@Injectable({
  providedIn: 'root'
})
export class CalibrationService {
  private calibrationServiceUrl = 'http://'+environment.machineApiUrl;  // URL to web api
  calibration:Array<CalibrationValues>;
  
  constructor(private logger:LoggerService,private http: HttpClient) { }
  getList(){
    
    return of(this.calibration);
  }
  getPeakIntegration(filename,peaks)
  {
    this.calibrationServiceUrl = 'http://'+environment.machineApiUrl;
    let peak=JSON.stringify(peaks);;

    return this.http.get(this.calibrationServiceUrl+'?PeakIntegration='+filename+':'+peak).pipe(
      map((item: any) => {
         let model=[];

          item['values']['peak'].forEach(it=>{
            model.push({id:it['id'],peakArea:it['area'].toFixed(2),peakTime:it['rtheight']/1000,sensitivity:Math.round(it['area'])})
          });
          
        
         return model;
       })
    );
  }
 setPeakCalibration(filename,peaks)
  {
    let peak=JSON.stringify(peaks).replace(/"id"/g, '"@id"')
    return this.http.get(this.calibrationServiceUrl+'?SetPeaksCalib='+filename+':'+peak).pipe(
      map((item: any) => {
         let model=[];

          console.log('risposta',item); 
          
        
         return model;
       })
    );
  }
}
