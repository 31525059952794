import { Component, OnInit, Input } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/grid-light';
import HC_exporting from 'highcharts/modules/exporting';
import annotations from 'highcharts/modules/annotations';
import HC_export_data from 'highcharts/modules/export-data';

HC_exporting(Highcharts);
HC_export_data(Highcharts);
annotations(Highcharts);

theme(Highcharts);

@Component({
  selector: 'app-chromatogram',
  templateUrl: './chromatogram.component.html',
  styleUrls: ['./chromatogram.component.scss']
})
export class ChromatogramComponent implements OnInit {
  @Input() fileName: string;
  chromatogramValues: Array<object>;

  // highcharts
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  
  chartOptions: Highcharts.Options = { 
    chart: {
      type: 'spline',
      zoomType: 'x',
      alignTicks: false
    },
    
    xAxis: [{ // xAxe
      labels: {
          format: '{value}s',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'Time (s)',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }],
    yAxis: [{ // Primary yAxis
      labels: {
          format: '{value} mV',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'PID Signal',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }, 
    { // Secondary yAxis
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: {
          text: 'Temperature',
          style: {
              color: Highcharts.getOptions().colors[5]
          }
      },
      labels: {
          format: '{value} °C',
          style: {
              color: Highcharts.getOptions().colors[5]
          }
      },
      opposite: true
    },],
  credits: {
    enabled:false
  },
  annotations: [{
    labelOptions: {
     
    },
    labels: [
     
    ]
}],
title: {
  text: "Chromatogram"
},
  plotOptions: {
    spline: {
        marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
        }
    },
    areaspline: {
     
      marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
              hover: {
                  enabled: true
              }
          }
      }
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ]
      }
    },
  },
  
  series: [{
      name: 'PID SIGNAL',
      data: [],
      type: 'spline',
      color: Highcharts.getOptions().colors[0]
    },{
      name: 'T° PRECONCENTRATOR',
      data: [],
      yAxis: 1,
      type: 'spline',
      visible:  false,
      color: Highcharts.getOptions().colors[5]
    },{
      name: 'T° COLUMN',
      data: [],
      yAxis: 1,
      type: 'spline',
      visible:  false,
      color: Highcharts.getOptions().colors[8]
    }
  ]}; 
  gasses: object={"71-43-2": "Benzene",	
  "108-88-3":"Toluene",	
  "100-41-4": "Ethylbenzene",	
  "1330-20-7": "Xylenes",
  };
  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {   } // optional function, defaults to null
  updateFlag: boolean = false; // optional boolean
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  
  chart: Object;
  saveInstance(chartInstance): void {
      this.chart = chartInstance;
  }

  constructor(private measure: ChromatogramsService) { }

  ngOnInit() {
   
  }

  generatePlotData() {
    let series: Array<any>=[];
    console.log("dati completi",this.chromatogramValues);
    this.chromatogramValues.forEach( item => {
      series.push([<number>item["Time"]/1000, <number>item["Value"]*1000]);
    })
    return  series;
  }
  generateTempData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Tempp"]*1]);
    })
    return series;
  }
  generateTempPpdData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Temppd"]*1]);
    })
    return series;
  }
  generateTempPcData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Tempc"]*1]);
    })
    return series;
  }
  ngOnChanges(){
    this.measure.getChromatogram(this.fileName).subscribe(res => {
      this.chromatogramValues = res;
      var concentration=this.generatePlotData(); 
      var temperature=this.generateTempData(); 
      var tempPc=this.generateTempPcData(); 
/*
      {
        point: {
            xAxis: 0,
            yAxis: 0,
            x: 96.2,
            y: 783
        },
        text: '6.1 km climb<br>4.6% on avg.'
    }
    */
      this.measure.getCompoundValues(this.fileName).subscribe(res =>{
       console.log("tento di recuperare le misure",res);
       this.chartOptions.title["text"]=res[0]['chromfile'];
       this.chartOptions.series["0"]["data"]=concentration;
       this.chartOptions.series["1"]["data"]=temperature;
       this.chartOptions.series["2"]["data"]=tempPc;
       this.chartOptions.annotations[0].labels=[];
       res.forEach(it=>{
          let annotation={};
          annotation['point']={};
          annotation['point']['x']=it['rttop']/1000;
          console.log(it['rttop']/1000);
          console.log(concentration);
          let locTop=concentration.find(e =>{ 
           
            if (e[0] == (it['rttop']/1000)) return e; 
        });
          console.log(locTop);
         annotation['point']['y']=locTop[1];
          annotation['point']['xAxis']=0;
          annotation['point']['yAxis']=0;
          annotation['text']=this.gasses[it['idgas']] + '<br />'+it['conc'].toFixed(2) + ' ppb';
          this.chartOptions.annotations[0].labels.push(annotation);
       });
      
       this.updateFlag=true;
       console.log("ecco qua le label",this.chartOptions);
      });
      
     
      
    }); 
  } 
}
