<h1>Sequencer Edit</h1>
<form [formGroup]="form">
    <mat-card formArrayName="sequencerDetails" class="form-container-name" >
    <mat-form-field appearance="fill"  >
        <mat-label>Sequencer Name</mat-label>
        <input name="name" type="numer" class="form-control"  matInput  formControlName="name" >
    </mat-form-field>
  </mat-card>
  <div formArrayName="sequencerSteps" class="form-container-step" >
       
      <mat-card
      class="sequencerSteps"
      *ngFor="
        let sequencerStep of form.get('sequencerSteps')?.controls;
        let sequencerStepIndex = index
      "
      [formGroupName]="sequencerStepIndex"
    >  

      
      <mat-form-field appearance="fill">
          <mat-label>Point</mat-label>
          <mat-select placeholder="point" formControlName="point" >
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
 
          </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
          <mat-label>Repetitions</mat-label>
          <input name="rpt" type="numer" class="form-control"  matInput  formControlName="rpt" >
      </mat-form-field>
      <mat-form-field appearance="fill">
          <mat-label>Method</mat-label>
          <mat-select placeholder="method" formControlName="method" class="form-control" >
              <mat-option *ngFor="
              let method of this.methodAList;
              let sequencerStepIndex = index
            " value="{{method.name}}">{{method.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
          <mat-label>Delay</mat-label>
          <input name="delay" type="numer" class="form-control"  matInput  formControlName="delay" >
      </mat-form-field> 
      <mat-slide-toggle name="alarmOn"  class="form-control" [checked]="alarmsOn[sequencerStepIndex]" (change)="onSequenceAlarm($event,sequencerStepIndex)" color="warn">
        Activate Alarms
      </mat-slide-toggle>
      <button mat-mini-fab  type="button" (click)="deleteSequencerStep(sequencerStepIndex)"><mat-icon>delete</mat-icon></button>
     

     
      <mat-expansion-panel formArrayName="alarms" class="panelAlarm" *ngIf="alarmsOn[sequencerStepIndex]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Alarms
          </mat-panel-title>
          <mat-panel-description>
            Set these values to abilitate alarms
            <mat-icon color="warn">warning</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div
          class="alarms"
          *ngFor="
            let alarm of sequencerStep.get('alarms').controls;
            let alarmIndex = index
          "
          [formGroupName]="alarmIndex"
        >
            <mat-form-field appearance="fill">
                <mat-label>Gas</mat-label>
                <input name="gasName" type="numer" class="form-control"  matInput  formControlName="gasName" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Calibration Value</mat-label>
                <input name="calibrationValue" type="numer" class="form-control"  matInput  formControlName="calibrationValue" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Low Value</mat-label>
                <input name="lowValue" type="numer" class="form-control"  matInput  formControlName="lowValue" >
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>High Value</mat-label>
                <input name="lowValue" type="numer" class="form-control"  matInput  formControlName="highValue" >
            </mat-form-field>
               
        </div>
        
        
      </mat-expansion-panel>
      

      <mat-expansion-panel formArrayName="onAlarmAction" class="panelAlarmStep" *ngIf="alarmsOn[sequencerStepIndex]">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Alarm Steps
          </mat-panel-title>
          <mat-panel-description>
            Steps to be done in case of Alarm
            <mat-icon color="warn">warning</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div
          class="alarmStep"
          *ngFor="
            let alarmStep of sequencerStep.get('onAlarmAction').controls;
            let alarmStepIndex = index
          "
          [formGroupName]="alarmStepIndex"
        >
        <mat-form-field appearance="fill">
          <mat-label>Point</mat-label>
          <mat-select placeholder="point" formControlName="point" >
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
 
          </mat-select>
      </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Repetitions</mat-label>
          <input name="rpt" type="numer" class="form-control"  matInput  formControlName="rpt" >
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Method</mat-label>
            <mat-select placeholder="method" formControlName="method" class="form-control" >
                <mat-option *ngFor="
                let method of this.methodAList;
                let sequencerStepIndex = index
              " value="{{method.name}}">{{method.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Delay</mat-label>
            <input name="delay" type="numer" class="form-control"  matInput  formControlName="delay" >
        </mat-form-field> 
              <button mat-mini-fab  type="button" (click)="deleteAlarmStep(sequencerStep, alarmStepIndex)"><mat-icon>delete</mat-icon></button>
            
        </div>
        
        <button  mat-fab  color="primary" type="button" (click)="addAlarmStep(sequencerStep)"><mat-icon>add</mat-icon></button>
      </mat-expansion-panel>
    </mat-card>
    <mat-card>
      <button mat-fab  color="primary" type="button" (click)="addSequencerStep()"><mat-icon>add</mat-icon></button>
    </mat-card>    
  </div>
  
  <mat-card class="addictional-functions">
      <h2>Functions</h2>
  <mat-card-actions>
      <button   mat-raised-button color="primary" [disabled]="form.invalid" type="button" (click)="saveSequencer(form)">
          Save
      </button>
    </mat-card-actions>
  </mat-card>
</form>
<!--
{{ this.form.value | json }}
//-->