<form class="example-form"  >
   
      <!--
         <mat-card class="select-sequencer">
        <div style="float:left; width:45%">Column Temp: <h1>{{rtStatus['ColumnTemp']}}</h1></div>
        <div style="float:left; width:45%">Pre Conc Temp: <h1>{{rtStatus['PreconcTemp']}}</h1></div>
      </mat-card>
      //-->
     
      
     
     
    <mat-card [ngClass]="['start-panel',startPanelClass]" >
      
      <button class="startButton"  mat-raised-button color="start"  type="button" (click)="startAnalysis()" [disabled]="startDisabled?true:null" *ngIf="status.status=='eReady'" style="height:5rem">
       <h2>Start Measure Cycle</h2><mat-icon>play_arrow</mat-icon>
      </button>
      <button class="stopButton"  mat-raised-button color="stop"  type="button" (click)="stopAnalysis()" [disabled]="stopDisabled?true:null" *ngIf="status.status=='eRunning'" >
        Stop Measure Cycle <mat-icon>stop</mat-icon>
       </button>
       <button class="startAutoCalibButton" style="margin-top:1rem" mat-raised-button color="calibration"  type="button" (click)="startAutoCalib()" [disabled]="startDisabled?true:null" *ngIf="status.status=='eReady'" >
        Force Check STD/Calibration <mat-icon>graphic_eq</mat-icon>
       </button>
 
    </mat-card>


    <mat-card class="status-panel">
      
      <mat-card class="current-operation">
        Status: <h3>{{rtStatus['statusOK']}}</h3><br />
        Actual Method: <br/>
        <h3>{{status.method}}</h3>
      </mat-card>
      <mat-card class="current-operation" *ngIf="lastCalibration">
        Last Calibration: <h3>{{lastCalibration.DateLast | date: "dd/MM/yyyy - HH:mm"}}</h3>
        Next Calibration: <h3>{{lastCalibration.DateNext | date: "dd/MM/yyyy - HH:mm"}}</h3>
      </mat-card>
      
    </mat-card>
    <mat-card class="status-panel">
      <mat-card class="current-operation" *ngIf="lastMeasure">
        
          Last measure:
        <br />
        <h3>{{lastMeasure.measure.measuredate | date: "dd/MM/yyyy - HH:mm"}} - {{lastMeasure.measure.method}}</h3> <br />
       Results:
        <br />
        <mat-card  style="background-color:#efefef;font-size:1.2rem;padding:0.5rem;margin-top:0.2rem; color:#333">
         
          <table>
        <tr *ngFor="let measure of lastMeasure.measuredata" style="border-bottom:1px dashed white;padding:0.2rem">
         <!--<td><strong>{{measure.gasid}}</strong></td>/--><td><strong>{{gasses[measure.gasid]}}</strong></td><td>{{measure.concentration | number:'1.2-2'}} ppb</td>
        </tr>
      </table>
      </mat-card><br />
         <button class="startButton"  mat-raised-button color="primary"  type="button" routerLink="/data-browser/id/{{lastMeasure.measure.measureid}}">Check Chromatogram&nbsp;<mat-icon>show_chart</mat-icon></button>
      
      </mat-card>
    </mat-card>

    <mat-card class="chromatogram">
      <div style="height:50vh">
      <app-chromatogram [actualStatus]="rtStatus['measurerunning']"></app-chromatogram>
    </div>
    </mat-card>