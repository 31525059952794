


<highcharts-chart 
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="chartOptions"
    [callbackFunction]="chartCallback"
    [(update)]="updateFlag"
    (chartInstance)="logChartInstance($event)"

    style="display: block; width:100%; height:100%; background-color: white;"  class="grafico"
    >
</highcharts-chart>    
                  