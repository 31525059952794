
  <header role="banner">
      <div class="logo">
          <img src="assets/img/logo.png">
      </div>
      <app-top-menu></app-top-menu>
  </header>

  <!-- Main Content -->

  <!--<router-outlet></router-outlet>-->
  <main class="main__container">
      <div class="main-container__bg"></div>
      <router-outlet></router-outlet>
  </main>

  <!-- Footer -->
  <app-footer></app-footer>
  
