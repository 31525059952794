import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef,MatFormField,MatLabel, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginDialogComponent } from './login-dialog.component';

@Injectable()
export class LoginDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<LoginDialogComponent>;
 
  public open(options) {
   
    this.dialogRef = this.dialog.open(LoginDialogComponent, {    
         data: {
           title: options.title,
           message: options.message,
           cancelText: options.cancelText,
           confirmText: options.confirmText,
           showCancel: options.showCancel
         }
    });
  }
  public close(){
      this.dialog.closeAll();
  }
  public confirmed(): Observable<any> {
    
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
       
        return res;
      }
    ));
  }
}