import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../shared/enviroment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Status
{
  ipAddress: string;
  serialNumber: string;
  errors: Array<systemError>;
  currentStatus: currentStatus;
}

export interface systemError
{
  id: number;
  description: string;
}

export interface currentStatus
{
  method?: string;
  sequencer?: string;
  planner?: string;
  status?: string;
  lastUpdate?: Date;
  runName?: string;
  point?: number;
  repeat?: number;
  currentRun?: number;
}


@Injectable({
  providedIn: 'root'
})
export class StatusService {
  private statusServiceUrl = 'http://'+environment.machineApiUrl;  // URL to web api
  statusCodes: object = {
    eUnknown: 0, /// Strumento sconosciuto    PYXIS
    eUninitialized: 1, /// Strumento non inizializzato  PYXIS
    eBakeout: 2,  /// Bakeout
    eLoading: 3, ///  loading method
    ePurge: 4,  ///  manual purge
    eRunning: 5,  ///  method Running    PYXIS
    ePreparing: 6,    /// Preparing     PYXIS
    eReady: 7, /// ready        PYXIS
    eShutdown: 8, /// shutdown
    eStandby: 9,  /// standby
    eWaiting: 10, /// waiting
    eDisconnected: 100
  }
  
       

  
  constructor( private http: HttpClient) { }

 
  getRtStatus():Observable<object>
  { 
    
    return this.http.get('http://'+environment.machineApiUrl+'?GetRtValues').pipe(
      map(el => {
        let status:object={};
        status=el["values"];
        if ((localStorage.getItem("calibration")=="1")&&(status['status']=='eReady')&&(localStorage.getItem("actualStatus")!='ePreparing')){
          this.resetAutoMeasureMode().subscribe(res => {
            localStorage.setItem("calibration","0");
          })

        }
        
        if ((localStorage.getItem("actualStatus")=='eStopping')&&(status['status']=='eRunning'))
          status['status']='eStopping';
        else
          if ((status['status']=="eReady")&&(localStorage.getItem("actualStatus")!='ePreparing')) localStorage.setItem("actualStatus", "");
          

        if ((localStorage.getItem("actualStatus")=='ePreparing')&&(status['status']!='eRunning'))
          status['status']='ePreparing';
        else
          if (status['status']=="eRunning") localStorage.setItem("actualStatus", "");

        return status;

       
      }),
      catchError(this.handleError<object>('getRtStatusReal', []))
    );
  }

  getStatus():Observable<currentStatus>
  { 
    
    return this.http.get('http://'+environment.machineApiUrl+'?GetInstrumentStatus').pipe(
      map(el => {
        
        let status:currentStatus={};
        status.status=el["values"]["status"];
        status.method="Waiting...";
        this.getActiveMethod().subscribe(res => {
          
          status.method = res["Active_method_file"];
          
        });
        return status;
      }),
      catchError(this.handleError<object>('getStatusReal', []))
    );
  }
  getActiveMethod()
  {
    return this.http.get<object>('http://'+environment.machineApiUrl+'?GetActiveMethod')
    .pipe(
      map(el => {
       
        return el["values"];
      }),
      catchError(this.handleError<object>('getActiveMethod', []))
    );
  }

  getConfig()
  {
    return this.http.get('http://'+environment.machineApiUrl+'?GetConfig')
    .pipe(
      map(el => {
        let ret=[];
        const ritorno:object={values:[]};
        for (let key in el["values"]) {
          let value = el["values"][key];
          if ((key=="switchonrestore")||(key=="logs")||(key=="instrumentplugin")||(key=="measuremode")||(key=="multipoint")||(key=="defaultseq")||(key=="defaultmth")||(key=="defaultsched")||(key=="valcodevicename")){
            ret.push({'key':key,'value':value});
            // Use `key` and `value`
          }
        }
        


        console.log(ret);
        ritorno['values']=ret;
        return ritorno;
      }),
      catchError(this.handleError<object>('getConfig', []))
    );
  }

  getVersion()
  {
    return this.http.get<object>('http://'+environment.machineApiUrl+'?GetVersion')
    .pipe(
      map(el => {
       
        return el["desc"];
      }),
      catchError(this.handleError<object>('GetVersion', []))
    );
  }

  getVersionLowLevel()
  {
    return this.http.get<object>('http://'+environment.machineApiUrl+'?GetVersionLowLevel')
    .pipe(
      map(el => {
       
        return el["desc"];
      }),
      catchError(this.handleError<object>('GetVersionLowLevel', []))
    );
  }
  getVersionPlugin()
  {
    return this.http.get<object>('http://'+environment.machineApiUrl+'?GetVersionPlugin')
    .pipe(
      map(el => {
       
        return el["desc"];
      }),
      catchError(this.handleError<object>('GetVersionPlugin', []))
    );
  }

  setConfig(config)
  {
    let config_json=JSON.stringify(config);
    return this.http.get('http://'+environment.machineApiUrl+'?SetConfig='+config_json).pipe(
      map((item: any) => {
         let model=[];

          console.log('risposta Set Config',item); 
        
         return model;
       }),
       catchError(this.handleError<object>('setConfig', []))
    );
  }
  
  setDateTime(datetime)
  {
    let datetime_json=JSON.stringify(datetime);
    console.log('risposta Set DateTime',datetime_json); 
    return this.http.get('http://'+environment.machineApiUrl+'?SetPBDateTime='+datetime_json)
    .pipe(
      map(el => {
        console.log('risposta Set DateTime',el); 
        return el;
      }),
      catchError(this.handleError<object>('getConfig', []))
    );
  }

  getDateTime()
  {
    return this.http.get('http://'+environment.machineApiUrl+'?GetPBDateTime')
    .pipe(
      map(el => {
        console.log("il tempo!",el);
        return el;
      }),
      catchError(this.handleError<object>('getDateTime', []))
    );
  }

  stopASAP(){ 
    
    this.http.get('http://'+environment.machineApiUrl+'?StopMeasure').pipe(
      map(el => {
        return el["status"];
      }),
      catchError(this.handleError<object>('StopMeasure', []))
    );
    
  }
  loadMethod(method)
  {
  
  }
  loadSequencer(sequencer)
  {
   
  }  
  loadPlanner(planner)
  {
   
  }  
  start():Observable<any>
  {
   
    return this.http.get('http://'+environment.machineApiUrl+'/?StartMeasure').pipe(
      map(el => {
        localStorage.setItem("actualStatus", "ePreparing");
        return el;
      }),
      catchError(this.handleError<object>('StartMeasure', []))
    );
    
  }
  resetAutoMeasureMode():Observable<any>
  {
    return this.http.get('http://'+environment.machineApiUrl+'/?SetMeasureMode=A').pipe(
      map(el => {
        return el;}
      )
    )
  }
  startAutoCalib():Observable<any>
  {
   

    return this.http.get('http://'+environment.machineApiUrl+'/?SetMeasureMode=M').pipe(
      map(el => {
        let el2:any;
        console.log("SET MEASURE OK");
      
        this.autocalib().subscribe(res => {
          
          el2 = res;
        });
        return el;
      }),
      catchError(this.handleError<object>('!!ExecuteAutoCalib', []))
    );
    
  }
  autocalib():Observable <any> {
    return this.http.get('http://'+environment.machineApiUrl+'/?ExecuteAutoCalib').pipe(
          map(el => {
            localStorage.setItem("actualStatus", "ePreparing");
            localStorage.setItem("calibration", "1");
                        console.log("AUTOCALIB STARTING ---->>>");
            if (el["status"]=="ERR") {
              localStorage.setItem("actualStatus", "eReady");
            localStorage.setItem("calibration", "0");
            }
            return el; 

          }),
          catchError(this.handleError<object>('ExecuteAutoCalib', []))
        );
  }
  startWhenReady()
  {
  
    this.http.get('http://'+environment.machineApiUrl+'/?StartMeasure').pipe(
      map(el => {
        localStorage.setItem("actualStatus", "ePreparing");
        return el;
      }),
      catchError(this.handleError<object>('StartMeasure', []))
    );
    
  
  }

  checkAdminPwd(user,pwd):Observable<any>
  {
     if (user=='admin_pyxis')
     {
       if (pwd=='pyxix_2019_kz'){
         return of(true);
       }
       
     }
     return of(false);
  }

  stop():Observable<any>
  {
    
    return this.http.get('http://'+environment.machineApiUrl+'/?StopMeasure').pipe(
      map(el => {
        localStorage.setItem("actualStatus", "eStopping");
        return el;
        
      }),
      catchError(this.handleError<object>('StopMeasure', []))
    );
    
  }
  getLastMeasure():Observable<any>
  {
    //?GetMeasureDb=L
    return this.http.get('http://'+environment.machineApiUrl+'/?GetMeasureDb=L').pipe(
      map(el => {
        return el["values"];
      }),
      catchError(this.handleError<object>('GetLastMeasure', []))
    );
    
  }
  getLastCalibration():Observable<any>
  {
    //?GetMeasureDb=L
    return this.http.get('http://'+environment.machineApiUrl+'/?GetNextCalibDate').pipe(
      map(el => {
        return el["values"];
      }),
      catchError(this.handleError<object>('GetNextCalibDate', []))
    );
    
  }
  restart()
  {
    return this.http.get('http://'+environment.machineApiUrl+'/?RestartApp').pipe(
      map(el => {
       
        return el;
        
      }),
      catchError(this.handleError<object>('RestartApp', []))
    );
  }

   /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error("ERRORE!!!!",error); // log to console instead
      localStorage.setItem("actualStatus", "eReady");
      localStorage.setItem("calibration", "0");

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
