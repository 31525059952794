<app-panels>
  <app-panel panelTitle="Compound Trends">
    <div class="container">

  
      <mat-card class="material-list">
        <form class="form" novalidate  [formGroup]="formSelectDate" >
          <mat-expansion-panel class="selectDatesPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>Select Date Period <span style="font-weight:normal">&nbsp; from <em>{{startDate.toLocaleDateString()}}</em> to <em>{{endDate.toLocaleDateString()}}</em></span></mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field  appearance="fill">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="picker_start_date" formControlName="startDate">
              <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
              <mat-datepicker #picker_start_date></mat-datepicker>
            </mat-form-field>&nbsp;
            <mat-form-field  appearance="fill">
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="picker_end_date" formControlName="endDate">
              <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
              <mat-datepicker #picker_end_date></mat-datepicker>
            </mat-form-field>
          </mat-expansion-panel>
        </form>
        <br />
        <mat-expansion-panel [(expanded)]="expandDates"  class="datesPanel">
          <mat-expansion-panel-header>
            <mat-panel-title>Select Day</mat-panel-title>
          </mat-expansion-panel-header>
      
          <table mat-table [dataSource]="dataSource"  matSort>
          <!-- Position Column -->
          <ng-container matColumnDef="datam">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let measure"> {{measure.datam | date:"dd/MM/yyyy"}} </td>
          </ng-container>
      
         
      
          <!-- Weight Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef style="text-align: center" > Show Daily Measures </th>
            <td mat-cell *matCellDef="let measure" style="text-align: center"><a (click)="loadDay(measure.measuredate,measure.method)" style="cursor:pointer"><mat-icon >search</mat-icon></a> </td>
          </ng-container>
      
          
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      
      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </mat-expansion-panel>
      </mat-card>
      <mat-card class="chromatogram"  *ngIf="showGraph">
      <mat-card      >
      
        <app-compound-graph [date]="actualDate" [compounds]="compounds"  ></app-compound-graph>
      </mat-card>
    </mat-card>
      </div>
  </app-panel>
</app-panels>