<app-panels>
  <app-panel panelTitle="Calibration" active="true">
    
<div class="container">

  <mat-card class="chromatogram"   *ngIf="showChroma" >

    <app-chromatogram [fileName]="chromatograName" [extraOption]="graphOption"  ></app-chromatogram>
  </mat-card>
<mat-card class="material-list">
  <form class="form" novalidate  [formGroup]="formSelectDate" >
    <mat-expansion-panel class="selectDatesPanel">
      <mat-expansion-panel-header>
        <mat-panel-title>Select Date Period <span style="font-weight:normal">&nbsp; from <em>{{startDate.toLocaleDateString()}}</em> to <em>{{endDate.toLocaleDateString()}}</em></span></mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field  appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker_start_date" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
        <mat-datepicker #picker_start_date></mat-datepicker>
      </mat-form-field>&nbsp;
      <mat-form-field  appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="picker_end_date" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
        <mat-datepicker #picker_end_date></mat-datepicker>
      </mat-form-field>
    </mat-expansion-panel>
  </form><br />
  <mat-expansion-panel [(expanded)]="expandDates" (opened)="openDates()" class="datesPanel">
    <mat-expansion-panel-header>
      <mat-panel-title>Select Day</mat-panel-title>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="dataSource"  #firstSort="matSort"  matSort>
    <!-- Position Column -->
    <ng-container matColumnDef="datam">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let measure"> {{measure.datam| date:"dd/MM/yyyy"}} </td>
    </ng-container>

   

    <!-- Weight Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef style="text-align: center" > Show Daily Measures </th>
      <td mat-cell *matCellDef="let measure" style="text-align: center"><a (click)="loadDay(measure.measuredate,measure.method)" style="cursor:pointer"><mat-icon >search</mat-icon></a> </td>
    </ng-container>

    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-expansion-panel>
<br />
<mat-expansion-panel [(expanded)]="expandMeasures" class="measuresPanel" >
  <mat-expansion-panel-header >
    <mat-panel-title>Select Measure</mat-panel-title>
    <mat-panel-description><span *ngIf="actualDate">on {{actualDate| date:"dd/MM/yyyy"}}</span></mat-panel-description>
  </mat-expansion-panel-header>

  <table mat-table [dataSource]="dataSourceDay"  #secondSort="matSort"  matSort>
       <!-- Position Column -->
       <ng-container matColumnDef="measuretype">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let measure"> <mat-icon *ngIf="measure.measuretype=='0'" style="color:green">show_chart</mat-icon> <mat-icon style="color:red" *ngIf="measure.measuretype=='1'">graphic_eq</mat-icon> </td>
      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="measuredate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let measure"> {{measure.measuredate| date:"HH:mm"}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Method </th>
        <td mat-cell *matCellDef="let measure"> {{measure.method}} </td>
      </ng-container>
  
      <!-- Weight Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="text-align: center" > Show Chromatogram </th>
        <td mat-cell *matCellDef="let measure" style="text-align: center"><a (click)="loadChromatogram(measure.chromfile,measure.method)" style="cursor:pointer"><mat-icon >timeline</mat-icon></a> </td>
      </ng-container>
  
      
  
      <tr mat-header-row *matHeaderRowDef="displayedColumnsDay"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsDay;"></tr>
  </table>
  <mat-paginator #paginatorDay [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-expansion-panel>
  <br />
  
</mat-card>
<mat-card class="calibration-card">
  <mat-expansion-panel [(expanded)]="expandCalibration" class="calibrationPanel" *ngIf="actualMeasure">
    <mat-expansion-panel-header >
      <mat-panel-title>Calibration Panel</mat-panel-title>
      <mat-panel-description>on {{actualMeasure}}</mat-panel-description>
    
    </mat-expansion-panel-header>
        <form class="example-form" novalidate  [formGroup]="form" >
          <table mat-table [dataSource]="dataSourceCalibration" class="calibrationTable" >

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? toggleRow(row) : null"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
              </td>
            </ng-container>
           

            <!-- Position Column -->
            <ng-container matColumnDef="compound">
              <th mat-header-cell *matHeaderCellDef > Compound </th>
              <td mat-cell *matCellDef="let calibration"> {{gasses[calibration.gasid]}} </td>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="PBS">
              <th mat-header-cell *matHeaderCellDef class="pbs"  style="width:5rem !important"> PBS </th>
              <td mat-cell *matCellDef="let calibration"  class="pbs"> 
                <span *ngIf="!showEdit(calibration)">{{calibration.PBS}}</span>
                <mat-form-field *ngIf="showEdit(calibration)"appearance="legacy"  style="width:5rem !important" >

                  <input [formControl]="formRow[0].value" class="form-control" type="number"  matInput value="{{calibration.PBS}}" required   >

                </mat-form-field>

              </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="PBW">
              <th mat-header-cell *matHeaderCellDef class="pbw"  style="width:2.5rem !important"> W </th>
              <td mat-cell *matCellDef="let calibration"  class="pbw"> 
                <span *ngIf="!showEdit(calibration)" >{{calibration.PBW}} </span>

                <mat-form-field *ngIf="showEdit(calibration)" appearance="legacy"  style="width:2.5rem !important" >

                  <input [formControl]="formRow[2].value" class="form-control" type="number" matInput value="{{calibration.PBW}}" required >

                </mat-form-field>
              </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="PMP">
              <th mat-header-cell *matHeaderCellDef  class="pmp" style="width:5rem !important"> PMP </th>
              <td mat-cell *matCellDef="let calibration"   class="pmp"> 
                <span *ngIf="!showEdit(calibration)">{{calibration.PMP}} </span>

                <mat-form-field *ngIf="showEdit(calibration)" appearance="legacy"  style="width:5rem !important">

                  <input [formControl]="formRow[3].value" class="form-control" type="number" matInput value="{{calibration.PMP}}" required   >

                </mat-form-field>
              </td>
            </ng-container>

             <!-- Name Column -->
             <ng-container matColumnDef="PBE">
              <th mat-header-cell *matHeaderCellDef  class="pbe" style="width:5rem !important"> PBE </th>
              <td mat-cell *matCellDef="let calibration"  class="pbe"> 
                <span *ngIf="!showEdit(calibration)">{{calibration.PBE}} </span>

                <mat-form-field *ngIf="showEdit(calibration)" appearance="legacy"  style="width:5rem !important">

                  <input [formControl]="formRow[1].value" class="form-control" type="number" matInput value="{{calibration.PBE}}" required   >

                </mat-form-field>
              </td>
              
            </ng-container>
           

             <!-- Name Column -->
             <ng-container matColumnDef="PMW">
              <th mat-header-cell *matHeaderCellDef class="pmw" style="width:2.5rem !important" >W </th>
              <td mat-cell *matCellDef="let calibration"  class="pmw" > 
                <span *ngIf="!showEdit(calibration)">{{calibration.PMW}} </span>

                <mat-form-field *ngIf="showEdit(calibration)" appearance="legacy" style="width:2.5rem !important" >

                  <input [formControl]="formRow[4].value" class="form-control" type="number" matInput value="{{calibration.PMW}}" required  >

                </mat-form-field>
              </td>
            </ng-container>

             <!-- Name Column -->
             <ng-container matColumnDef="concentration">
              <th mat-header-cell *matHeaderCellDef  style="width:2.5rem !important"> CONC </th>
              <td mat-cell *matCellDef="let calibration"  > 
                <span *ngIf="!showEdit(calibration)">{{calibration.concentration}}</span>
                <mat-form-field *ngIf="showEdit(calibration)" appearance="legacy" style="width:2.5rem !important">

                  <input [formControl]="formRow[5].value" class="form-control" type="number" matInput value="{{calibration.concentration}}" required  >

                </mat-form-field>

              </td>
            </ng-container>

             <!-- Operation Column -->
             
            <ng-container matColumnDef="instruments">
              <th mat-header-cell *matHeaderCellDef style="text-align: center" > Tools </th>
              <td mat-cell *matCellDef="let calibration" style="text-align: center">
                <a (click)="zoomTo(calibration)" *ngIf="!isZoomed(calibration)" style="cursor:pointer"><mat-icon >zoom_in</mat-icon></a>
                <a (click)="deZoom(calibration)" *ngIf="isZoomed(calibration)" style="cursor:pointer"><mat-icon >zoom_out</mat-icon></a><br />
                <a (click)="editMeasure(calibration)" *ngIf="!showEdit(calibration)" style="cursor:pointer"><mat-icon >edit</mat-icon></a>
                <a (click)="saveMeasure(calibration)" *ngIf="showEdit(calibration)" style="cursor:pointer"><mat-icon style="color:red">save</mat-icon></a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCalibration"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCalibration;" ></tr>
        </table>
        
      </form>
  </mat-expansion-panel>
  <div class="calibration-result"   *ngIf="showChroma">
    <mat-expansion-panel [(expanded)]="expandResult" class="resultPanel" *ngIf="showChroma">
      <mat-expansion-panel-header >
        <mat-panel-title>CALIBRATION RESULTS</mat-panel-title>
        <mat-panel-description>on {{actualMeasure}}</mat-panel-description>
      </mat-expansion-panel-header>
      <table mat-table [dataSource]="dataSourceResults">
        <ng-container matColumnDef="compound">
          <th mat-header-cell *matHeaderCellDef > Compound </th>
          <td mat-cell *matCellDef="let result"> {{result.compound}} </td>
        </ng-container>
        <ng-container matColumnDef="peakArea">
          <th mat-header-cell *matHeaderCellDef > Peak Area </th>
          <td mat-cell *matCellDef="let result"> {{result.peakArea}} </td>
        </ng-container>
        <ng-container matColumnDef="peakTime">
          <th mat-header-cell *matHeaderCellDef > Peak Time (sec) </th>
          <td mat-cell *matCellDef="let result"> {{result.peakTime}} </td>
        </ng-container>
        <ng-container matColumnDef="sensitivity">
          <th mat-header-cell *matHeaderCellDef > Sensitivity (mV/ppb) </th>
          <td mat-cell *matCellDef="let result"> {{result.sensitivity}} </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumnsResults"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsResults;"></tr>
      </table>
    </mat-expansion-panel>
  <mat-card class="controlPanel">
    <button mat-raised-button color="primary" (click)="calibrate()" [disabled]="isNoneSelected()">CALIBRATE</button>
    <button mat-raised-button color="accent" (click)="saveCalibration()"  [disabled]="acceptBtn">ACCEPT</button>
    <button mat-raised-button color="warn" (click)="discardCalibration()"  [disabled]="discardBtn">DISCARD</button>
  </mat-card>
  
  
  </div>
</mat-card>




</div>
</app-panel>


</app-panels>