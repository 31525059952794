import { Component, OnInit, Input } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import {StatusService} from '../../../services/status.service';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/grid-light';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
import { interval, Subscription } from 'rxjs';
HC_exporting(Highcharts);
HC_export_data(Highcharts);
theme(Highcharts);

@Component({
  selector: 'app-chromatogram', 
  templateUrl: './chromatogram.component.html',
  styleUrls: ['./chromatogram.component.scss']
})
export class ChromatogramComponent implements OnInit {
  @Input() fileName: string;
  @Input() actualStatus: string;
  private subscriptions: Subscription[]=[];
  chromatogramValues: Array<object>=[];
  modus: string='single';
  // highcharts
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  
  chartOptions: Highcharts.Options = { 
    chart: {
      type: 'spline',
      zoomType: 'x',
      alignTicks: false
    },
    
    title: {
      text: "Chromatogram"
    },
    xAxis: [{ // xAxe
      labels: {
          format: '{value}s',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'Time (s)',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }],
    yAxis: [{ // Primary yAxis
      labels: {
          format: '{value} mV',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'PID Signal',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }, 
    { // Secondary yAxis
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: {
          text: 'Temperature',
          style: {
              color: Highcharts.getOptions().colors[5]
          }
      },
      labels: {
          format: '{value} C°',
          style: {
              color: Highcharts.getOptions().colors[5]
          }
      },
      opposite: true
    },],
  credits: {
    enabled:false
  },
  plotOptions: {
    spline: {
        marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
        }
    },
    areaspline: {
      
      marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
              hover: {
                  enabled: true
              }
          }
      }
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
        ]
      }
    },
  },
  
  series: [{
      name: 'PID SIGNAL',
      data: [],
      type: 'spline',
      color: Highcharts.getOptions().colors[0]
    },{
      name: 'T° PRECONCENTRATOR',
      data: [],
      yAxis: 1,
      visible:  false,
      type: 'spline',
      color: Highcharts.getOptions().colors[5]
    },{
      name: 'T° COLUMN',
      data: [],
      yAxis: 1,
      visible:  false,
      type: 'spline',
      color: Highcharts.getOptions().colors[8]
    }
  ]}; 

  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {   } // optional function, defaults to null
  updateFlag: boolean = false; // optional boolean
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  
  chart: Object;
  saveInstance(chartInstance): void {
      this.chart = chartInstance;
  }

  constructor(private measure: ChromatogramsService,) { }

  ngOnInit() {
    let RtSamples:number= +localStorage.getItem("RtSamples");
    if (this.actualStatus=="eStop"){
          
      localStorage.setItem("RtSamples","0");
      localStorage.setItem("RtChromatogramValues","[]");
      RtSamples=0;
      this.chromatogramValues=[];
    }
    if (!RtSamples)
      RtSamples=0;
    if ((this.chromatogramValues.length==0))
      RtSamples=0;
    let lastData;
    if (this.actualStatus=="eReady"){
         
          var concentration=this.generatePlotData(); 
          var temperature=this.generateTempData(); 
          var tempPc=this.generateTempPcData(); 
          this.chartOptions.title["text"]=this.fileName;
          this.chartOptions.series["0"]["data"]=concentration;
          this.chartOptions.series["1"]["data"]=temperature;
          this.chartOptions.series["2"]["data"]=tempPc;
          this.updateFlag=true;
          localStorage.setItem("RtSamples",RtSamples.toString());
          
          localStorage.setItem('RtChromatogramValues', JSON.stringify(this.chromatogramValues)); 
          console.log("ACTST",this.actualStatus);
          
      
     
    }else {
      
      this.subscriptions.push(interval(2000).subscribe((value: number) => {
        if (this.actualStatus=="eStop"){
            
          localStorage.setItem("RtSamples","0");
          localStorage.setItem("RtChromatogramValues","[]");
          localStorage.setItem("ShowTempPreconcentrator","0");
          localStorage.setItem("ShowTempColumn","0");
          RtSamples=0;
          this.chromatogramValues=[];
        }
        this.measure.getChromatogramRealtime(RtSamples).subscribe(res => {
          let oldRtSamples=RtSamples;
        
          res.forEach( it =>{
            lastData=Date.now();
            this.chromatogramValues.push(it);
            RtSamples++;
            localStorage.setItem("LastData",lastData);
          });
        
          
          var concentration=this.generatePlotData(); 
          var temperature=this.generateTempData(); 
          var tempPc=this.generateTempPcData(); 
          this.chartOptions.title["text"]=this.fileName;
          this.chartOptions.series["0"]["data"]=concentration;
          this.chartOptions.series["1"]["data"]=temperature;
          this.chartOptions.series["2"]["data"]=tempPc;
          this.chartOptions.series["1"]["visible"]=this.chart['series'][1]['visible'];
          this.chartOptions.series["2"]["visible"]=this.chart['series'][2]['visible'];
          this.updateFlag=true;
          localStorage.setItem("RtSamples",RtSamples.toString());
          
          localStorage.setItem('RtChromatogramValues', JSON.stringify(this.chromatogramValues)); 
          console.log("ACTST",this.actualStatus);
          
      
        })
      }));
    } 
  } 
  // Demonstrate chart instance
  logChartInstance(chart: Highcharts.Chart) {
    console.log('Chart instance: ', chart);
    this.chart=chart;
  }
  generatePlotData() {
    let series: Array<any>=[];
    
    this.chromatogramValues.forEach( item => {
      series.push([<number>item["Time"]/1000, <number>item["Value"]*1000]);
    })
    return  series;
  }
  generateTempData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Tempp"]*1]);
    })
    return series;
  }
  generateTempPpdData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Temppd"]*1]);
    })
    return series;
  }
  generateTempPcData() {
    let series: Array<any>=[];
    this.chromatogramValues.forEach( item => { 
      series.push([<number>item["Time"]/1000, <number>item["Tempc"]*1]);
    })
    return series;
  }
  ngOnChanges(){
  }
  ngOnDestroy(){
    this.subscriptions.forEach(sub=>{
      sub.unsubscribe();
    })
  }
}
