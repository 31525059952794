import { Component, OnInit, Input } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import { ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-chromatogram-data',
  templateUrl: './chromatogram-data.component.html',
  styleUrls: ['./chromatogram-data.component.scss']
})
export class ChromatogramDataComponent implements OnInit {
  @Input() fileName: string;
  chromatogramValues: Array<object>;
  displayedColumns: string[] = ['time', 'value', 'temperature', 'tempc', 'tempp'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private measure: ChromatogramsService) { }

  ngOnInit() {
  }
  ngOnChanges(){
    this.measure.getChromatogram(this.fileName).subscribe(res => {
      this.chromatogramValues = res;
      this.dataSource = new MatTableDataSource<any>(this.chromatogramValues);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }); 
  } 

  downloadFile() {
    let data=this.chromatogramValues;
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], {type: 'text/csv' })
    saveAs(blob, this.fileName+".csv");
}
}
