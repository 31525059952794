<div class="header">
    <h1 mat-dialog-title>{{ data.title  }}</h1>
</div> 
<form class="form" novalidate  [formGroup]="form" >
<div mat-dialog-content>
    
    <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input name="username" class="form-control" matInput  formControlName="usernameField" >
    </mat-form-field>
    &nbsp;&nbsp;
    <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input name="password" class="form-control" type="password" matInput  formControlName="passwordField" >
    </mat-form-field>
   
    <p class="dialog-message">{{ data.message }}</p>
</div>
<div mat-dialog-actions>
    <button *ngIf="data.showCancel" class="btn btn-cancel" mat-raised-button style="margin-right:10px;" (click)="cancel()">{{ data.cancelText }}</button>
    <button class="btn" mat-raised-button  (click)="confirm(form)">{{ data.confirmText }}</button>
</div>
</form>
