import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import { MethodService, MethodFields, MethodValues } from '../../../services/method.service';
import { CalibrationService,  } from '../../../services/calibration.service';
import {StatusService} from '../../../services/status.service';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core'
import {Router} from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import { ConfirmDialogService } from '../../../shared/confirm-dialog/confirm-dialog.service'

interface singleRow{
  key: string;
  value: FormControl;
}
@Component({
  selector: 'app-measure-list',
  templateUrl: './measure-list.component.html',
  styleUrls: ['./measure-list.component.scss']
})

export class MeasureListComponent implements OnInit {
 
  form: FormGroup;
  chromatograName:string;
  showChroma: boolean=false;
  showData: boolean=false;
  dataName:string;
  measures: Array<object>;
  measuresDay: Array<object>;
  displayedColumns: string[] = ['datam',  'select'];
  displayedColumnsDay: string[] = ['measuretype','measuredate', 'method', 'select'];
  displayedColumnsCalibration: string[] = ['select','compound', 'PBS',  'PBW', 'PMP', 'PMW', 'PBE',  'concentration', 'instruments'];
  displayedColumnsResults: string[] = ['compound', 'peakArea', 'peakTime', 'sensitivity']; 
  dataSource: MatTableDataSource<any>;
  dataSourceDay: MatTableDataSource<any>;
  dataSourceCalibration: MatTableDataSource<any>;
  dataSourceResults: MatTableDataSource<any>;
  actualDate: string;
  actualMeasure: string;
  methodName:string;
  expandMeasures: boolean=false;
  expandDates: boolean=true;
  expandCalibration:boolean=false;
  expandResult:boolean=true;
  selection = new SelectionModel<any>(true, []);
  graphOption: Array<object> = [];
  compoundToEdit: string="";
  compoundZoomed: string="";
  formRow: Array<singleRow>=[];
  indexes: Array<number>=[];
  animal: string;
  name: string;
  calibrateBtn:boolean=false;
  acceptBtn:boolean=true;
  discardBtn:boolean=true;
  formSelectDate: FormGroup;
  startDate: Date = new Date();
  endDate: Date = new Date();
  gasses: object={"71-43-2": "Benzene",	
  "108-88-3":"Toluene",	
  "100-41-4": "Ethylbenzene",	
  "1330-20-7": "Xylenes",
  };
  @ViewChild('firstSort') sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('secondSort') sortDay: MatSort;
  @ViewChild('paginatorDay') paginatorDay: MatPaginator;

  constructor(private statusService: StatusService, private dialogService: ConfirmDialogService,private method: MethodService ,private measure: ChromatogramsService, private calibration: CalibrationService,private _formBuilder: FormBuilder, private router:Router, private cd: ChangeDetectorRef) { }
  ngOnInit() {
    this.form = this._formBuilder.group({
      gasCalibration: this._formBuilder.array([])
    });
    this.startDate.setMonth(this.startDate.getMonth()-1);
    this.measure.getList(this.startDate,this.endDate).subscribe(res => {
    
      this.measures = res;
      this.dataSource = new MatTableDataSource<any>(this.measures);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
    }); 
    this.formSelectDate = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
    

    this.formSelectDate.get('startDate').setValue(this.startDate);
    this.formSelectDate.get('endDate').setValue(this.endDate);
    this.onChanges();
  }
  
  onChanges() {
    this.formSelectDate.valueChanges.subscribe(val => {
      this.startDate=val.startDate;
      this.endDate= val.endDate;

      this.measure.getList(this.startDate,this.endDate).subscribe(res => {
        console.log("io qui vedo",res);
        this.measures = res;
        this.dataSource = new MatTableDataSource<any>(this.measures);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }); 
    });
  }

  loadDay(day,method){
    console.log("ha caricato il Giorno",day,method);
    this.measure.getListDayMethod(day.substr(0,10),method).subscribe(res => {
      console.log('giorno',res);
      this.measuresDay = res;
      this.dataSourceDay = new MatTableDataSource<any>(this.measuresDay);
      this.dataSourceDay.paginator = this.paginatorDay;
      this.dataSourceDay.sort = this.sortDay;
      this.expandMeasures=true;
      this.expandDates=false;
      this.expandCalibration=false;
      this.actualDate=day.substr(0,10);
    }); 
  }
  openDates(){
    this.expandMeasures=false;
    this.expandCalibration=false;
  }

  loadChromatogram(fileName,methodName){
    console.log("ha caricato il chroma",fileName);
    this.chromatograName=fileName;
    this.graphOption=[];
    this.selection = new SelectionModel<any>(true, []);
    this.actualMeasure=fileName;
    this.showChroma=true;
    this.showData=false;
    this.expandMeasures=false;
    this.expandDates=false;
    this.expandCalibration=true;
    this.methodName=methodName;
    this.method.getCalibration(methodName).subscribe(res => {
      console.log(res);
      
      this.dataSourceCalibration = new MatTableDataSource(res.peak);
      let results=[];

      

      let i=0;
      this.dataSourceCalibration.data.forEach(row=>{
        
        this.indexes[row['compound']]=i;
        results.push({compound:this.gasses[row['gasid']],peakArea:'*',peakTime:'*',sensitivity:'*'});
        console.log(this.indexes);
        i++;
      });
      console.log(results);
      this.dataSourceResults = new MatTableDataSource(results);
      console.log(this.dataSourceResults);
    });
    
  }
  loadData(fileName){
    console.log("ha caricato i dati",fileName);
    this.dataName=fileName;
    this.showChroma=false;
    this.showData=true;

  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceCalibration.data.length;
    return numSelected === numRows;
  }
  isNoneSelected() {
    const numSelected = this.selection.selected.length;
   
    return numSelected === 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const tempOption: Array<object>=[];
    if (this.isAllSelected())
    {
      this.selection.clear();
      this.dataSourceCalibration.data.forEach(row => { delete tempOption[row['compound']];});
      this.graphOption=tempOption;
      this.cd.detectChanges();
    }
    else {
        
        this.dataSourceCalibration.data.forEach(row => {
         // delete this.graphOption[row['compound']];
          this.selection.select(row);
          tempOption[row['compound']]=Array();
          tempOption[row['compound']]['drawLines']=true;
          tempOption[row['compound']]['zoomTo']=false;
          tempOption[row['compound']]['PBS']=row['PBS'];
          tempOption[row['compound']]['PBE']=row['PBE'];
          tempOption[row['compound']]['PMP']=row['PMP'];
          tempOption[row['compound']]['PBW']=row['PBW'];
          tempOption[row['compound']]['PMW']=row['PMW'];
          
        });
        this.graphOption=tempOption;
        console.log("Selezione totale",this.graphOption);
        this.compoundZoomed="";
        this.cd.detectChanges();
    }
  }

  toggleRow(row?){
    this.selection.toggle(row);
   
    if (this.selection.isSelected(row)){
      delete this.graphOption[row['compound']];
      this.graphOption[row['compound']]=Array();
      this.graphOption[row['compound']]['drawLines']=true;
      this.graphOption[row['compound']]['zoomTo']=this.isZoomed(row);
      this.graphOption[row['compound']]['PBS']=row['PBS'];
      this.graphOption[row['compound']]['PBE']=row['PBE'];
      this.graphOption[row['compound']]['PMP']=row['PMP'];
      this.graphOption[row['compound']]['PBW']=row['PBW'];
      this.graphOption[row['compound']]['PMW']=row['PMW'];
      this.cd.detectChanges();
      
    }
    else
    {
      
      delete this.graphOption[row['compound']];
       
    }
    console.log("toggle",this.graphOption );
  }
   // creazione del form
   toFormGroup( ) {
    let group: any = {};
   
      
    return new FormGroup(group);
  }

  editMeasure(row){
    //da inserire il controllo errori!!!!
    
    this.formRow=[];
    this.compoundToEdit=row['compound'];
    this.graphOption[row['compound']]=Array();
    this.graphOption[row['compound']]['drawLines']=true;
    this.graphOption[row['compound']]['zoomTo']=this.isZoomed(row);
    this.graphOption[row['compound']]['PBS']=row['PBS'];
    this.graphOption[row['compound']]['PBE']=row['PBE'];
    this.graphOption[row['compound']]['PMP']=row['PMP'];
    this.graphOption[row['compound']]['PBW']=row['PBW'];
    this.graphOption[row['compound']]['PMW']=row['PMW'];
    if (!this.selection.isSelected(row)){this.selection.toggle(row)}
    this.formRow.push({key: 'PBS', value: new FormControl(row['PBS'])});
    this.formRow.push({key: 'PBE', value: new FormControl(row['PBE'])});
    this.formRow.push({key: 'PMP', value: new FormControl(row['PMP'])});
    this.formRow.push({key: 'PBW', value: new FormControl(row['PBW'])});
    this.formRow.push({key: 'PMW', value: new FormControl(row['PMW'])});
    this.formRow.push({key: 'concentration', value: new FormControl(row['concentration'])});
    console.log(this.formRow);
  }
  saveMeasure(row){
    this.compoundToEdit=undefined;
    console.log(this.formRow);
    if (this.formRow[0].value.touched){
      this.dataSourceCalibration.data[this.indexes[row['compound']]]['PBS']=this.formRow[0].value.value;
      row['PBS']=this.formRow[0].value.value;
    }
    if (this.formRow[1].value.touched){
      this.dataSourceCalibration.data[this.indexes[row['compound']]]['PBE']=this.formRow[1].value.value;
      row['PBE']=this.formRow[1].value.value;
    }
    if (this.formRow[3].value.touched){
      this.dataSourceCalibration.data[this.indexes[row['compound']]]['PMP']=this.formRow[3].value.value;
      row['PMP']=this.formRow[3].value.value;
    }
    if (this.formRow[2].value.touched){
      this.dataSourceCalibration.data[this.indexes[row['compound']]]['PBW']=this.formRow[2].value.value;
      row['PBW']=this.formRow[2].value.value;
    }
    if (this.formRow[4].value.touched){
      this.dataSourceCalibration.data[this.indexes[row['compound']]]['PMW']=this.formRow[4].value.value;
      row['PMW']=this.formRow[4].value.value;
    }
    if (this.formRow[5].value.touched){
      this.dataSourceCalibration.data[this.indexes[row['compound']]]['concentration']=this.formRow[5].value.value;
      row['concentration']=this.formRow[5].value.value;
    }
    
    console.log(this.dataSourceCalibration.data);

    this.graphOption[row['compound']]=Array();
    this.graphOption[row['compound']]['drawLines']=true;
    this.graphOption[row['compound']]['zoomTo']=this.isZoomed(row);
    
    this.graphOption[row['compound']]['PBS']=row['PBS'];
    this.graphOption[row['compound']]['PBE']=row['PBE'];
    this.graphOption[row['compound']]['PMP']=row['PMP'];
    this.graphOption[row['compound']]['PBW']=row['PBW'];
    this.graphOption[row['compound']]['PMW']=row['PMW'];
    console.log(this.formRow);
  }

  showEdit(row){
    return this.compoundToEdit==row['compound'];
  }
  zoomTo(row){
    this.graphOption[row['compound']]=Array();
   
    this.graphOption[row['compound']]['drawLines']=this.selection.isSelected(row);
    this.graphOption[row['compound']]['zoomTo']=true;
    this.graphOption[row['compound']]['PBS']=row['PBS'];
    this.graphOption[row['compound']]['PBE']=row['PBE'];
    this.graphOption[row['compound']]['PMP']=row['PMP'];
    this.graphOption[row['compound']]['PBW']=row['PBW'];
    this.graphOption[row['compound']]['PMW']=row['PMW'];
    this.compoundZoomed=row['compound']
    this.cd.detectChanges();
  }
  deZoom(row){
    this.graphOption[row['compound']]=Array();
    this.graphOption[row['compound']]['drawLines']=this.selection.isSelected(row);
    this.graphOption[row['compound']]['zoomTo']=false;
    this.graphOption[row['compound']]['deZoom']=true;
    this.graphOption[row['compound']]['PBS']=row['PBS'];
    this.graphOption[row['compound']]['PBE']=row['PBE'];
    this.graphOption[row['compound']]['PMP']=row['PMP'];
    this.graphOption[row['compound']]['PBW']=row['PBW'];
    this.graphOption[row['compound']]['PMW']=row['PMW'];
    console.log('qui ci sono ora');
    this.compoundZoomed="";
  }
  isZoomed(row){
    return (this.compoundZoomed==row['compound']);
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
   
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  calibrate()
  {
    //richiesta dell'area al servizio calibration
    //{peak:[{id:0,rtstart:130000,rtstartth:4000,rtend:151000,rtendth:4000,rtheight:141000,rtheightth:5000}]}
    let peaks:object={peak:[]};
    this.dataSourceCalibration.data.forEach(row => {
      if (this.selection.isSelected(row)){
        let item={};
        item['id']=this.indexes[row['compound']];
        item['rtstart']=row['PBS']*1000;
        item['rtend']=row['PBE']*1000;
        item['rtheight']=row['PMP']*1000;
        item['rtendth']=row['PBW']*1000;
        item['rtheightth']=row['PMW']*1000;
        peaks['peak'].push(item);
      }
    });
    let i=0;
    this.dataSourceResults.data.forEach(row=>{
      this.dataSourceResults.data[i]['peakArea']='*';
      this.dataSourceResults.data[i]['peakTime']='*';
      this.dataSourceResults.data[i]['sensitivity']='*';
      i++;
    });
    this.calibration.getPeakIntegration(this.chromatograName,peaks).subscribe(res => {
      res.forEach(item=>{
        this.dataSourceResults.data[item['id']]['peakArea']=item['peakArea'];
        this.dataSourceResults.data[item['id']]['peakTime']=item['peakTime'];
        // attenzione, questa roba dovrebbe stare nel servizio, vabbè
        this.dataSourceResults.data[item['id']]['sensitivity']=item['sensitivity']/this.dataSourceCalibration.data[item['id']]['concentration'];
      });
      
      
    });
    this.acceptBtn=false;
    this.discardBtn=false;
  }
  discardCalibration(){
    let i=0;
    this.dataSourceResults.data.forEach(row=>{
      this.dataSourceResults.data[i]['peakArea']='*';
      this.dataSourceResults.data[i]['peakTime']='*';
      this.dataSourceResults.data[i]['sensitivity']='*';
      i++;
    });
    this.acceptBtn=true;
    this.discardBtn=true;
  }
  saveCalibration(){
    const options = {
      title: 'Attention!',
      message: "If you continue, sensitivity data of the calibrated compounds will be updated.",
      cancelText: 'CANCEL',
      confirmText: 'YES, CONTINUE',
      showCancel: true
    };
    
    this.dialogService.open(options);
    let peaks:object={peak:[]};
    let calibpoints:object={pointc:[]};
    this.dataSourceCalibration.data.forEach(row => {
      if (this.selection.isSelected(row)){
        let item={};
        //item['id']=this.indexes[row['compound']];
        item['rtstart']=row['PBS']*1000;
        item['rtend']=row['PBE']*1000;
        item['rtheight']=row['PMP']*1000;
        item['rtstartth']=row['PBW']*1000;
        item['rtendth']=row['PBW']*1000;
        item['rtheightth']=row['PMW']*1000;
        calibpoints={pointc:[]};
        calibpoints = [];
        var cal_point = [{ id: 0, areacalib: this.dataSourceResults.data[ this.indexes[row['compound']]]['peakArea'], concentration: this.dataSourceCalibration.data[ this.indexes[row['compound']]]['concentration'] }];


       
        calibpoints = { pointc: cal_point };
        item['calibpoints']=calibpoints;
        var calibration=item;
        var peak_obj = { id: this.indexes[row['compound']], calibration };
        peaks['peak'].push(peak_obj);
    
      }
    });
    console.log('saved data', peaks);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
           console.log("e allora salva!");
           this.statusService.getRtStatus().subscribe(resS => {
            if (resS['status']=='eReady') {
              this.calibration.setPeakCalibration(this.methodName,peaks).subscribe(res => {
                const options = {
                  title: 'Peaks Saved',
                  message: "Your new peak setup has been saved.",
                  cancelText: 'CANCEL',
                  confirmText: 'CONTINUE',
                  showCancel: false
                };
                this.dialogService.open(options);
                this.acceptBtn=true;
                this.discardBtn=true;
              });
            }
            else { 
              const options = {
                title: 'Peaks NOT Saved',
                message: "The active method could not be changed during execution mode",
                cancelText: 'CANCEL',
                confirmText: 'CONTINUE',
                showCancel: false
              };
              this.dialogService.open(options);
              this.acceptBtn=true;
              this.discardBtn=true;
            }
           console.log("Ecco il res",resS);
          });
         }
       });
  }
}

