import { Component, OnInit, Input, DoCheck, KeyValueDiffers, OnChanges } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import * as Highcharts from 'highcharts';
import theme from 'highcharts/themes/grid-light';
import HC_exporting from 'highcharts/modules/exporting';
import HC_export_data from 'highcharts/modules/export-data';
HC_exporting(Highcharts);
HC_export_data(Highcharts);
theme(Highcharts);
export class Compound{
  
  compound:string;
  compoundName:string;
}
@Component({
  selector: 'app-compound-graph',
  templateUrl: './compound-graph.component.html',
  styleUrls: ['./compound-graph.component.scss']
})

export class CompoundGraphComponent implements OnInit,OnChanges,DoCheck  {
  @Input() date: string;
  @Input() compounds:Array<Compound>=[];
  
  chromatogramValues: Array<object>;
  dailyValues: Array<object>;
  // highcharts
  Highcharts: typeof Highcharts = Highcharts; // required
  chartConstructor: string = 'chart'; // optional string, defaults to 'chart'
  gasses: object={"71-43-2": "Benzene",	
  "108-88-3":"Toluene",	
  "100-41-4": "Ethylbenzene",	
  "1330-20-7": "Xylenes",
  };
  chartOptions: Highcharts.Options = { 
    chart: {
      type: 'line',
      zoomType: 'x',
      alignTicks: false
    },
    
    title: {
      text: "Chromatogram"
    },
    xAxis: { // xAxe
      
      type: 'datetime',
    
      title: {
          text: 'Date/Time',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    },
    yAxis: [{ // Primary yAxis
      labels: {
          format: '{value} ppb',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      },
      title: {
          text: 'Concentration',
          style: {
              color: Highcharts.getOptions().colors[0]
          }
      }
    }
      ],
  credits: {
    enabled:false
  },
  plotOptions: {
    spline: {
        marker: {
            radius: 4,
            lineColor: '#666666',
            lineWidth: 1
        }
    },
    areaspline: {
     
      marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
              hover: {
                  enabled: true
              }
          }
      }
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          'viewFullscreen',
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadPDF',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ]
      }
    },
  },
  
  series: [  {
    name: 'Concentration',
    data: [],
    type: 'line',
    color: Highcharts.getOptions().colors[0],
    marker: {
      enabled: true
  }
  },
  {
    name: 'Concentration',
    data: [],
    type: 'line',
    color: Highcharts.getOptions().colors[1], marker: {
      enabled: true
  }
  },
  {
    name: 'Concentration',
    data: [],
    type: 'line',
    color: Highcharts.getOptions().colors[2], marker: {
      enabled: true
  }
  },
  {
    name: 'Concentration',
    data: [],
    type: 'line',
    color: Highcharts.getOptions().colors[3], marker: {
      enabled: true
  }
  },
  
  ]}; 

  chartCallback: Highcharts.ChartCallbackFunction = function (chart) {   } // optional function, defaults to null
  updateFlag: boolean = false; // optional boolean
  runOutsideAngular: boolean = false; // optional boolean, defaults to false
  differ:any;
  chart: Object;
  saveInstance(chartInstance): void {
      this.chart = chartInstance;
  }

  constructor(private measure: ChromatogramsService, private differs_A: KeyValueDiffers, ) {
    this.differ = differs_A.find({}).create();
   }

  ngOnInit() {
   
  }

  
  generateConcData(res) {
    let series: Array<any>=[];
   res.forEach( item => { 
      series.push({x:<number>item['measuredate'],y:Math.round( <number>item['concentration'] * 100 + Number.EPSILON ) / 100});
    })
    console.log("serie",series);
    return series;
  }
  ngDoCheck() {
    var changes = this.differ.diff(this.compounds);
    if (changes){
      let i=0;
      console.log( "Compounds",this.compounds);
     
      
        // Use `key` and `value`
        this.measure.getDailyValues(this.date).subscribe(res =>{
          this.chartOptions.series=[];
          this.chartOptions.series.length=0;
          console.log("daily data",res);
          this.dailyValues=res;
          for (let key in this.compounds) {
            let value = this.compounds[key];
            let gasdata=[];
            res.forEach(item => {
              if (value['compound']==item['idgasid']){
                gasdata.push(item);
              }
            });
            this.chartOptions.series.push({name: this.gasses[value['compound']] ,data: this.generateConcData(gasdata), type: 'line',color: Highcharts.getOptions().colors[key] });
          }
          this.chartOptions.title.text="Compound Trends - "+this.date;
          this.updateFlag=true;
          console.log( "Compounds dd",this.chartOptions.series);
        });
      
    
    
    }
    
  
  }
  ngOnChanges(){
    
   
  } 
}
