import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatSlideToggleModule,MatSelectModule, MatDividerModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,MatIconModule,MatCardModule, MatButtonModule } from '@angular/material';

import { SequencerListComponent } from './sequencer-list/sequencer-list.component';
import { MethodListComponent } from './method-list/method-list.component';
import { SequencerEditComponent } from './sequencer-edit/sequencer-edit.component';
import { MethodEditComponent } from './method-edit/method-edit.component';
import { MethodSequencerDashboardComponent } from './method-sequencer-dashboard/method-sequencer-dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgxChartsModule} from '@swimlane/ngx-charts';


const methodSequencerRoutes: Routes = [
  { path: 'method-sequencer-manager',  component: MethodSequencerDashboardComponent },
  { path: 'method-sequencer-manager/method-list',  component: MethodSequencerDashboardComponent },
  { path: 'method-sequencer-manager/sequencer-list',  component: MethodSequencerDashboardComponent },
  { path: 'method-sequencer-manager/method-edit/:id', component: MethodSequencerDashboardComponent },
  { path: 'method-sequencer-manager/method-edit',  component: MethodSequencerDashboardComponent },
  { path: 'method-sequencer-manager/sequencer-edit',  component: MethodSequencerDashboardComponent },
  { path: 'method-sequencer-manager/sequencer-edit/:id', component: MethodSequencerDashboardComponent },
];

export const methodSequencerRouting = RouterModule.forChild(methodSequencerRoutes);

@NgModule({
  imports: [
   CommonModule,
   methodSequencerRouting,
   SharedModule,
   MatTableModule,
   MatPaginatorModule,
   MatSortModule,
   MatFormFieldModule,
   MatInputModule,
   MatIconModule,
   MatCardModule,
   MatExpansionModule,
   MatDividerModule,
   MatSelectModule,
   MatSlideToggleModule,
   ReactiveFormsModule, FormsModule, MatButtonModule, RouterModule, NgxChartsModule
  ],
  declarations: [ SequencerListComponent, SequencerEditComponent, MethodEditComponent, MethodSequencerDashboardComponent, MethodListComponent,]
})
export class MethodSequencerManagerModule { }


