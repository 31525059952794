
<mat-card *ngIf="!fileName">
  <h3>Select a measure on the left!</h3>
</mat-card>


<highcharts-chart *ngIf="fileName"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="chartOptions"
    [callbackFunction]="chartCallback"
    [(update)]="updateFlag"
    style="display: block; width:100%; height:100%; background-color: white;"  class="grafico"
    >
</highcharts-chart>    
                  