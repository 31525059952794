import { ChangeDetectionStrategy, Component, HostListener, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import {FormBuilder, FormControl, Validators, FormGroup, FormArray} from '@angular/forms';

@Component({
changeDetection: ChangeDetectionStrategy.OnPush,
selector: 'app-login-dialog',
templateUrl: './login-dialog.component.html',
styles: [`
  .header, .dialog-message {
      text-transform: lowercase;
  }
  .header::first-letter, .dialog-message::first-letter {
      text-transform: uppercase;
  }
  .btn-cancel {
      background-color: red;
      color: #fff;
  }
`]
})
export class LoginDialogComponent {
  form: FormGroup;
  ngOnInit(){
    this.form = new FormGroup({
      usernameField: new FormControl(),
      passwordField: new FormControl(),
     });
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                  cancelText: string,
                  confirmText: string,
                  message: string,
                  title: string,
                  showCancel?: boolean
              }, private mdDialogRef: MatDialogRef<LoginDialogComponent>) { }
  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm(value) {
    console.log("kkk",value);
    if ( value.value["usernameField"]=='admin_pyxis')
    {
      if (value.value["passwordField"]=='pyxix_2019_kz'){
        this.close(true);
      }else {
        this.close(true);
      }
      
    } else {
      
    }
  }
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }
}