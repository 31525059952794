import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes }  from '@angular/router';
import { StartDashboardComponent } from './start-dashboard/start-dashboard.component';
import { SharedModule } from  '../../shared/shared.module';
import { StartAnalysisComponent } from './start-analysis/start-analysis.component';
import { ChromatogramComponent } from './chromatogram/chromatogram.component';
import { ReportComponent } from './report/report.component'; 
import { MatSlideToggleModule,MatSelectModule, MatDividerModule, MatExpansionModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule,MatIconModule,MatCardModule, MatButtonModule } from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HighchartsChartModule } from 'highcharts-angular';

const startRoutes: Routes = [
  { path: 'start',  component: StartDashboardComponent },
  { path: 'start/start-analysis',  component: StartDashboardComponent },
  { path: 'start/chromatogram',  component: StartDashboardComponent },
  { path: 'start/report',  component: StartDashboardComponent }
];

export const startRouting = RouterModule.forChild(startRoutes);

@NgModule({
  imports: [
   CommonModule,
   startRouting,
   SharedModule,
   MatTableModule,
   MatPaginatorModule,
   MatSortModule,
   MatFormFieldModule,
   MatInputModule,
   MatIconModule,
   MatCardModule,
   MatExpansionModule,
   MatDividerModule,
   MatSelectModule,
   MatSlideToggleModule,
   HighchartsChartModule,
   ReactiveFormsModule, FormsModule, MatButtonModule, RouterModule, 
  ],
  declarations: [
  StartDashboardComponent,
  StartAnalysisComponent,
  ChromatogramComponent,
  ReportComponent,] 
})
export class StartModule {
}