<app-panels>
  <app-panel panelTitle="General Settings">
    <form class="form" novalidate  >
    <mat-card class="configuration"  *ngIf="login==true&&restarting==false">
      <mat-card>
        <strong style="color:red">WARNING!</strong>
        <p>Modifications on configuration will be stored in Pyxis immediately, but will be applied only after a restart.<br/>
        <strong>Click on "save and restart Pyxis" to apply all the changes</strong></p>
      </mat-card>
      <table mat-table [dataSource]="dataSource" >
        <!-- Position Column -->
        <ng-container matColumnDef="configuration">
          <th mat-header-cell *matHeaderCellDef > Configuration </th>
          <td mat-cell *matCellDef="let config"> {{config.key}} </td>
        </ng-container>
    
       
    
        <!-- Weight Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef style="text-align: center" > Value </th>
          <td mat-cell *matCellDef="let config" style="text-align: center">
            <span *ngIf="!showEdit(config)"> {{config.value}}</span>
            <mat-form-field *ngIf="showEdit(config)" appearance="legacy">

              <input [formControl]="formRow[0].value" class="form-control" matInput value="{{config.value}}" required  >

            </mat-form-field>

          </td>
        </ng-container>
    
        <ng-container matColumnDef="instruments">
          <th mat-header-cell *matHeaderCellDef style="text-align: center" > Tools </th>
          <td mat-cell *matCellDef="let config" style="text-align: center">
    
            <a (click)="editMeasure(config)" *ngIf="!showEdit(config)" style="cursor:pointer"><mat-icon >edit</mat-icon></a>
            <a (click)="saveMeasure(config)" *ngIf="showEdit(config)" style="cursor:pointer"><mat-icon style="color:red">save</mat-icon></a>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </mat-card>
    </form>
    <form class="form" novalidate  [formGroup]="form" >
      <mat-card class="form-container"   *ngIf="login==true">
        <h2>GLOBAL MACHINE INFO</h2><br />
        <strong>Version:</strong> {{version}}<br />
        <strong>Version Low Level:</strong> {{versionLowLevel}}<br />
        <strong>Version Plugin:</strong> {{versionPlugin}}<br />
        <strong>Version GUI:</strong> 0.9.3beta
      </mat-card>
      <mat-card class="form-container"   *ngIf="login==true&&restarting==false">
             <mat-form-field appearance="fill">
                
                <mat-label>Pyxis IP</mat-label>
                <input name="pixysIp" class="form-control" matInput required formControlName="pixysIpField" >
                
              </mat-form-field><br />
              <button mat-raised-button color="warn" [disabled]="form.invalid" type="button" (click)="saveIP(form)">
                Change Pyxis IP only&nbsp;<mat-icon>refresh</mat-icon>
              </button>
              <br /><br />
              <mat-form-field  appearance="fill">
                <mat-label>Pyxis system date</mat-label>
                <input matInput [matDatepicker]="picker_date" formControlName="pixysDate">
                <mat-datepicker-toggle matSuffix [for]="picker_date"></mat-datepicker-toggle>
                <mat-datepicker #picker_date></mat-datepicker>
              </mat-form-field>
              <br />
              <mat-form-field  appearance="fill" style="width:14rem">
                <mat-label>Pyxis system time</mat-label>
                  <input [ngxTimepicker]="picker" [format]="24"  matInput  formControlName="pixysTime" [disableClick]="true" readonly>
                  <ngx-material-timepicker-toggle [for]="picker" style="margin-top:-30px;float:right"></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker #picker></ngx-material-timepicker>
                </mat-form-field>
              <br />
           
      </mat-card>

      <mat-card class="form-container"   *ngIf="login==true&&restarting==false">
        <mat-card>
          <strong style="color:red">WARNING!</strong>
          <p>Modifications on configuration will be stored in Pyxis immediately, but will be applied only after a restart.<br/>
            Restarting the Pyxis will interrupt current measuring.<br />
          <strong>Click on "save and restart Pyxis" to apply all the changes</strong></p>
        </mat-card><br />
        <button mat-raised-button color="warn" [disabled]="form.invalid" type="button" (click)="saveMethod(form)">
          Save & Restart Pyxis&nbsp;<mat-icon>refresh</mat-icon>
        </button>
      </mat-card>
      <mat-card class="form-container"   *ngIf="login==true&&restarting==true">
        <mat-card>
          <strong style="color:red">WARNING! PYXIS IS RESTARTING</strong>
          <p>The procedure may take some minutes.<br />
          <strong>Try to reload this page to access again to the Pyxis Dashboard</strong></p>
        </mat-card><br />
        
      </mat-card>
    </form>
    <mat-card   *ngIf="login==false">
      This section needs admin credentials
    </mat-card>
  </app-panel>
</app-panels>