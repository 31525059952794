import { Injectable } from '@angular/core';
import { of } from 'rxjs';

export interface SequencerValues
{
  name: string;
  id: number;
  steps?: Array<SequencerStep>;
}
export interface SequencerAlarm
{
  gasName: string;
  calibrationValue: number;
  lowValue: number;
  highValue: number;
}
export interface SequencerStep
{
  number: number;
  point:number;
  rpt: number;
  method: string;
  methodId?: number;
  delay: number;
  alarms?: Array <SequencerAlarm>;
  alarmOn?: boolean;
  rptOnAlarm: number;
  onAlarmAction?: Array<AlarmStep>;
}
export interface AlarmStep{
  number?: number;
  point:number;
  rpt: number;
  method: string;
  methodId?: number;
  delay: number;
}

@Injectable({
  providedIn: 'root'
})
export class SequencerService {
  sequencers: Array<SequencerValues> = [
    { name: 'Sequencer A',
      id: 0,
      steps: [
      {
        alarms: [
            {gasName: "Gas001", calibrationValue: 120, lowValue: 55, highValue: 134},
            {gasName: "Gas002", calibrationValue: 130, lowValue: 44, highValue: 168},
            {gasName: "Gas003", calibrationValue: 120, lowValue: 44, highValue: 220}
          ],
        delay: 55,
        method: "Method C",
        number: null,
        onAlarmAction: [
          {point: 2, rpt: 88, method: "Method C", delay: 88},
          {point: 3, rpt: 88, method: "Method D", delay: 88}
          ],
        point: 3,
        alarmOn: true,
        rptOnAlarm: null,
        rpt: 444},
      {
        alarmOn:false,
        delay: 88,
        method: "Method D",
        number: null,
        onAlarmAction: [],
        point: 3,
        rptOnAlarm: null,
        rpt: 3
      }
      ]
    },
    { name: 'Sequencer B', 
      id: 1},
    { name: 'Sequencer C', 
      id: 2},
    { name: 'Sequencer D', 
      id: 4},
    { name: 'Sequencer A1', 
      id: 5},
    { name: 'Sequencer B1', 
      id: 6},
    { name: 'Sequencer C1', 
      id: 7},
    { name: 'Sequencer D1', 
      id: 8},
    { name: 'Sequencer A2', 
      id: 9},
    { name: 'Sequencer B2', 
      id: 10},
    { name: 'Sequencer C2', 
      id: 11},
    { name: 'Sequencer D2', 
      id: 12},
    
  ];

 
  constructor() { }
  getList()
  {
    return of(this.sequencers);
  }
  getSequencer(id: number)
  {
    return of(this.sequencers[id]);
  }
  updateSequencer( sequencer: SequencerValues)
  {
   console.log("Metodo salvato");
    console.log(sequencer);
    return of(true);
  }
}
