import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../../services/logger.service';
import { MethodService, MethodFields, MethodValues } from '../../../services/method.service';
import {FormBuilder, FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import * as shape from 'd3-shape';
import * as d3 from 'd3';
import { RendererAnimationPlayer } from '@angular/platform-browser/animations/src/animation_builder';

@Component({
  selector: 'app-method-edit',
  templateUrl: './method-edit.component.html',
  styleUrls: ['./method-edit.component.scss']
})

export class MethodEditComponent implements OnInit {
  fields: Array <MethodFields>;
  pidFields: Array <MethodFields>;
  form: FormGroup;
  id:string;
  values:MethodValues;
  latestTemp:Number;
  ramps:Array<any> =[];
  rampsMin: Array<any> =[];
 
  // cose per il grafico da verificare
  multi: any[];

  // dati finti
 
  generatePlotData() {
    let series: Array<any>=[];
    let lastEndTemp: number;
    let endTime: number=0;
    series.push({name:0, value:this.form.get('basetemp').value});
    this.temperatureRamps.value.forEach( item => {
      endTime= +endTime + +item.time;
      console.log(endTime);
      series.push({name:endTime, value:<number>item.endtemp});
      lastEndTemp=item.endtemp;
    })
    series.push({name:+this.form.get('totcycletime').value,value:lastEndTemp});
    
    return [
      {
        name: "Temperature Ramps",
        series
      }
    ];
  }
  // opzioni varie
  view: any[] = [700, 400];
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  showYAxisLabel = true;
  yAxisLabel = 'Temperature';
  
  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  
  // line, area
  autoScale = true;
  theme = 'dark';
  chartType: string;
  chartGroups: any[];
  chart: any;
  realTimeData: boolean = false;
  countries: any[];
  single: any[];
  fiscalYearReport: any[];
  dateData: any[];
  dateDataWithRange: any[];
  calendarData: any[];
  statusData: any[];
  sparklineData: any[];
  timelineFilterBarData: any[];
  graph: { links: any[]; nodes: any[] };
  bubble: any;
  linearScale: boolean = false;
  range: boolean = false;
  width: number = 700;
  height: number = 300;
  fitContainer: boolean = false;

  // options
  legendTitle = 'Legend';
  legendPosition = 'right';
  tooltipDisabled = false;
  showText = true;
  showGridLines = true;
  innerPadding = '10%';
  barPadding = 8;
  groupPadding = 16;
  roundDomains = false;
  maxRadius = 10;
  minRadius = 3;
  showSeriesOnHover = true;
  roundEdges: boolean = true;
  animations: boolean = true;
  xScaleMin: any;
  xScaleMax: any;
  yScaleMin: number;
  yScaleMax: number;
  showDataLabel = false;
  noBarWhenZero = true;
  trimXAxisTicks = true;
  trimYAxisTicks = true;
  rotateXAxisTicks = true;
  maxXAxisTickLength = 16;
  maxYAxisTickLength = 16;

  //funzioni di approssimazione, togliere quello che non serve
  curves = {
    Basis: shape.curveBasis,
    'Basis Closed': shape.curveBasisClosed,
    Bundle: shape.curveBundle.beta(1),
    Cardinal: shape.curveCardinal,
    'Cardinal Closed': shape.curveCardinalClosed,
    'Catmull Rom': shape.curveCatmullRom,
    'Catmull Rom Closed': shape.curveCatmullRomClosed,
    Linear: shape.curveLinear,
    'Linear Closed': shape.curveLinearClosed,
    'Monotone X': shape.curveMonotoneX,
    'Monotone Y': shape.curveMonotoneY,
    Natural: shape.curveNatural,
    Step: shape.curveStep,
    'Step After': shape.curveStepAfter,
    'Step Before': shape.curveStepBefore,
    default: shape.curveLinear
  };

  // line interpolation
  curveType: string = 'Linear';
  curve: any = this.curves[this.curveType];
  interpolationTypes = [
    'Basis',
    'Bundle',
    'Cardinal',
    'Catmull Rom',
    'Linear',
    'Monotone X',
    'Monotone Y',
    'Natural',
    'Step',
    'Step After',
    'Step Before'
  ];

  closedCurveType: string = 'Linear Closed';
  closedCurve: any = this.curves[this.closedCurveType];
  closedInterpolationTypes = ['Basis Closed', 'Cardinal Closed', 'Catmull Rom Closed', 'Linear Closed'];
  colorSets: any;
  schemeType: string = 'ordinal';
  selectedColorScheme: string;
  rangeFillOpacity: number = 0.15;

  // line, area
  timeline = true;

  // margin
  margin: boolean = false;
  marginTop: number = 40;
  marginRight: number = 40;
  marginBottom: number = 40;
  marginLeft: number = 40;
  showRightYAxisLabel: boolean = true;

// fine grafico


  constructor(private method: MethodService ,private fb: FormBuilder,private route: ActivatedRoute, private location: Location,private router:Router) {
    //dati per il grafico
   }
  
  ngOnInit() {
   
    // lettura dei campi
    this.method.getMethodStructure().subscribe(res => {
      this.fields = res;
    });
    
    this.method.getPidMethodStructure().subscribe(res => {
      this.pidFields = res;
    });
    
    
    
    //controllo se modifica o creazione
    this.id=this.route.snapshot.paramMap.get('id');

    //verificare se meglio questo
    this.route.paramMap.subscribe(params => {
      console.log("Ecco i parametri");
      console.log(params);
    });
    // creazione form
    this.form = this.toFormGroup();
    this.latestTemp=this.form.get('basetemp').value;
    this.form.addControl('temperature_ramps',this.fb.array([]));
    this.addTemperatureRamp(this.latestTemp);
    // aggancio dela temperatura base alla prima rampa, verificare se spostare
    this.form.get('basetemp').valueChanges.subscribe(value => {
      let temps=this.form.get('temperature_ramps') as FormArray;
      let item=temps.at(0);
      item.get('starttemp').setValue(value);
      item.get('endtemp').setValidators([Validators.required,  Validators.min(value)]);
      item.get('endtemp').updateValueAndValidity();
    });
    this.multi=this.generatePlotData(); 
    this.multi=[...this.multi]; // ok, this shit is boh!
    this.form.valueChanges.subscribe(() => {
      this.multi=this.generatePlotData();
      this.multi=[...this.multi];
    });

    // popolamento campi
    if (this.id){
      this.method.getMethod(this.id).subscribe(res => {
        console.log("carico il metodo",res);
        if (res!==undefined)
        {
          this.values=res;
        }
         this.updateData();
          
      });
    }
    
    // creazione del form
    // rampe di temperatura
    

  }
  
  
  // controllo errori campi base
  public hasError = (controlName: string, errorName: string) =>{
    return this.form.controls[controlName].hasError(errorName);
  }

  // controllo errori rampa
  public hasErrorRamp = (index:number, controlName: string, errorName: string) =>{
    let temps=this.form.get('temperature_ramps') as FormArray;
    return (<FormArray> temps.at(index)).controls[controlName].hasError(errorName);
  }
  
  // salvataggio del metodo
  saveMethod(value){
    console.log("salva");
    console.log(value.value);
    this.method.updateMethod(value.value).subscribe(() => this.goBack());
    
  }
  goBack(): void {
    this.router.navigate(['/method-sequencer-manager/method-list']);
  }
  updateData(){
   
      this.fields.forEach(field => {
        this.form.get(field.name).setValue(this.values.basicparameters.configparam[field.name]);
       // group[field.name] =  new FormControl(this.values.basicparameters.configparam[field.name] || field.default, [Validators.required, Validators.min(field.min), Validators.max(field.max)]);
      });
      this.pidFields.forEach(field => {
        this.form.get(field.name).setValue(this.values.basicparameters.configparam[field.name]);
       // group[field.name] =  new FormControl(this.values.basicparameters.configparam[field.name] || field.default, [Validators.required, Validators.min(field.min), Validators.max(field.max)]);
      });
      this.latestTemp=this.form.get('basetemp').value;
       
          //carico i dati di rampa
          this.form.addControl('temperature_ramps',this.fb.array([]));
          let iter=0;
          console.log('TEMPERATURE',this.values);
          if (this.values.temperatureramp.stepramps!=null){
            this.deleteTemperatureRamp(0);
            this.values.temperatureramp.stepramps.forEach(ramp=>{
              this.addTemperatureRamp(null,{id:iter,time:ramp.time,starttemp:ramp.starttemp,endtemp:ramp.endtemp});
              iter++;
            });
          } 
    
  }
  // creazione del form
  toFormGroup( ) {
    let group: any = {};
   
      this.fields.forEach(field => {
        if (field.name=="name") { field.type="text" } else { field.type="number"}
        group[field.name] =  new FormControl(field.default || '', [Validators.required, Validators.min(field.min), Validators.max(field.max)]);
      });
    
      this.pidFields.forEach(field => {
        if (field.name=="name") { field.type="text" } else { field.type="number"}
        group[field.name] =  new FormControl(field.default || '', [Validators.required, Validators.min(field.min), Validators.max(field.max)]);
      });
    return new FormGroup(group);
  }

  // getter delle rampe
  get temperatureRamps() {
    return this.form.get('temperature_ramps') as FormArray;
  }

  // aggiunta riga rampa
  addTemperatureRamp(tempValue?,savedValues?) {
    if (tempValue == null){
      if (savedValues==null){
        let temps=this.form.get('temperature_ramps') as FormArray;
        let item=temps.at(temps.length-1);
        this.rampsMin[temps.length]=item.get('endtemp').value;
        this.temperatureRamps.push(this.fb.group({
          time:0,
          starttemp:[{value:item.get('endtemp').value, disabled:true}],
          endtemp: [{value:item.get('endtemp').value, disabled:false},[Validators.required, Validators.min(item.get('endtemp').value)]]
        }));
        let idx=temps.length-1;
        item.get('endtemp').valueChanges.subscribe(value => {
          let item_bis=temps.at(idx);
          item_bis.get('starttemp').setValue(value);
          item_bis.get('endtemp').clearValidators();
          this.rampsMin[idx]=value;
          item_bis.get('endtemp').setValidators([Validators.required,  Validators.min(value)]);
          item_bis.get('endtemp').updateValueAndValidity();
        });
      }
      else
      {
        let temps=this.form.get('temperature_ramps') as FormArray;
        let item=temps.at(temps.length-1);
        if(savedValues.id!=0){
          this.rampsMin[temps.length]=item.get('endtemp').value;
        }
        this.temperatureRamps.push(this.fb.group({
          time:savedValues.time,
          starttemp:[{value:savedValues.starttemp, disabled:true}],
          endtemp: [{value:savedValues.endtemp, disabled:false},[Validators.required, Validators.min(savedValues.starttemp)]]
        }));
        if(savedValues.id!=0){
          let idx=temps.length-1;
          item.get('endtemp').valueChanges.subscribe(value => {
            let item_bis=temps.at(idx);
            item_bis.get('starttemp').setValue(value);
            item_bis.get('endtemp').clearValidators();
            this.rampsMin[idx]=value;
            item_bis.get('endtemp').setValidators([Validators.required,  Validators.min(value)]);
            item_bis.get('endtemp').updateValueAndValidity();
          });
        }
        else{
          this.rampsMin[0]=savedValues.starttemp;
          // aggancio dela temperatura base alla prima rampa, verificare se spostare
          this.form.get('basetemp').valueChanges.subscribe(value => {
            let temps=this.form.get('temperature_ramps') as FormArray;
            let item=temps.at(0);
            item.get('starttemp').setValue(value);
            item.get('endtemp').setValidators([Validators.required,  Validators.min(value)]);
            item.get('endtemp').updateValueAndValidity();
            this.multi=this.generatePlotData(); 
            this.multi=[...this.multi]; // ok, this shit is boh!
          });
        }
      }
    }
    else {
      this.rampsMin[0]=tempValue;
      this.temperatureRamps.push(this.fb.group({time:0,starttemp:[{value:tempValue||0, disabled:true}],
        endtemp: [{value:tempValue, disabled:false},[Validators.required, Validators.min(tempValue)]]}));
    }
    this.multi=this.generatePlotData(); 
    this.multi=[...this.multi]; // ok, this shit is boh!
  }

  // cancellazione rampa
  deleteTemperatureRamp(index) {
    this.temperatureRamps.removeAt(index);
  }

}

