<app-panels>
  <app-panel panelTitle="Method List" active="{{active_method_list}}" routerLink="/method-sequencer-manager/method-list">
    <app-method-list>
    </app-method-list>
  </app-panel>
  <app-panel panelTitle="New/Edit Method" active="{{active_method_edit}}"  [routerLink]="active_method_edit? null :'/method-sequencer-manager/method-edit'">
    <app-method-edit>
    </app-method-edit>
  </app-panel>
  <app-panel panelTitle="Sequencer List" active="{{active_sequencer_list}}" routerLink="/method-sequencer-manager/sequencer-list">
      <app-sequencer-list>
      </app-sequencer-list>
  </app-panel>
  <app-panel panelTitle="New/Edit Sequencer" active="{{active_sequencer_edit}}" [routerLink]="active_sequencer_edit? null :'/method-sequencer-manager/sequencer-edit'">
    <app-sequencer-edit>
    </app-sequencer-edit>
  </app-panel> 
</app-panels>
