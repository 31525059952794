import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ChromatogramsService } from '../../../services/chromatograms.service';
import { MethodService, MethodFields, MethodValues } from '../../../services/method.service';
import { CalibrationService,  } from '../../../services/calibration.service';
import { FormArray, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ViewChild } from '@angular/core'
import {Router} from '@angular/router';

@Component({
  selector: 'app-status-dashboard',
  templateUrl: './status-dashboard.component.html',
  styleUrls: ['./status-dashboard.component.scss']
})
  

export class StatusDashboardComponent implements OnInit {
  compounds: Array<object>=[];
  displayedColumns: string[] = ['datam',  'select'];
  dataSource: MatTableDataSource<any>;
  actualDate: string;
  methodName: string;
  measures: Array<object>;
  expandDates: boolean=true;
  showGraph: boolean=false;
  startDate: Date = new Date();
  endDate: Date = new Date();
  formSelectDate: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  constructor(private method: MethodService ,private measure: ChromatogramsService, private router:Router) { }

  ngOnInit() {
    
    this.startDate.setMonth(this.startDate.getMonth()-1);
    this.measure.getList(this.startDate,this.endDate).subscribe(res => {
    
      this.measures = res;
      this.dataSource = new MatTableDataSource<any>(this.measures);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort=this.sort;
    }); 

    this.formSelectDate = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
    

    this.formSelectDate.get('startDate').setValue(this.startDate);
    this.formSelectDate.get('endDate').setValue(this.endDate);
    this.onChanges();

  }

  onChanges() {
    this.formSelectDate.valueChanges.subscribe(val => {
      this.startDate=val.startDate;
      this.endDate= val.endDate;

      this.measure.getList(this.startDate,this.endDate).subscribe(res => {
        console.log("io qui vedo",res);
        this.measures = res;
        this.dataSource = new MatTableDataSource<any>(this.measures);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }); 
    });
  }
  loadDay(day,method){
    console.log("ha caricato il Giorno",day,method);
    this.methodName=method;
    this.compounds=[];
    this.actualDate=day.substr(0,10);
    this.method.getCalibration(method).subscribe(res => {
      
      let i=0;
      res.peak.forEach(row=>{
        this.compounds.push({compound: row['gasid'], compoundName: row['compound']});
        this.showGraph=true;
        console.log(this.compounds);
        i++;
      });
    
    });

  }

}
